import { ReactNode } from 'react';

export enum HintTextVariant {
  DEFAULT = 'default',
  WARNING = 'warning',
}

export interface IHintTextProps {
  variant?: HintTextVariant;
  children?: ReactNode;
}
