import { cloneElement, FC, ReactElement, ReactNode } from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import classNames from 'classnames';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InfoRow,
  Tooltip,
  Typography,
} from 'src/components/ui';
import { HelpCircle, InfoHelpIcon } from 'src/assets/icons';
import { FormControlLabelProps } from '@mui/material';
import { theme } from 'src/theme';
import styles from './form.module.scss';

type FormItemProps<T extends FieldValues = FieldValues> = {
  children: ReactElement;
  label?: ReactNode;
  help?: ReactNode;
  required?: boolean;
  isControlLabel?: boolean;
  viewMode?: boolean;
  renderView?: (value: any, onChange: any) => ReactElement;
  renderViewValue?: (value: any) => ReactNode;
  noMargin?: boolean;
  tooltip?: ReactNode;
  tooltipPlacement?: 'label' | 'input';
  notRenderView?: boolean;
  showRequiredAsterisk?: boolean;
  className?: string;
  helperTextPlacement?: 'bottom' | 'right';
  formControlLabelProps?: Partial<FormControlLabelProps>;
  hideNotRequiredLabel?: boolean;
} & Omit<ControllerProps<T>, 'render'>;

const FormItemTooltip: FC<{ title: ReactNode }> = ({ title }) => (
  <Tooltip title={title}>
    <IconButton className={styles.tooltip}>
      <InfoHelpIcon />
    </IconButton>
  </Tooltip>
);

const FormItemLabelTooltip: FC<{ title: ReactNode; label: ReactNode }> = ({
  title,
  label,
}) => (
  <div className={styles.containerTitle}>
    <Typography fontSize={12}>{label}</Typography>
    <Tooltip title={title} describeChild arrow placement="bottom-start">
      <HelpCircle color={theme.palette.primary.main} />
    </Tooltip>
  </div>
);

export const FormItem = <T extends FieldValues = FieldValues>({
  name,
  label,
  help,
  required,
  rules,
  isControlLabel,
  noMargin,
  viewMode,
  renderView,
  renderViewValue,
  children,
  tooltip,
  tooltipPlacement = 'input',
  notRenderView,
  showRequiredAsterisk = false,
  className,
  helperTextPlacement = 'bottom',
  formControlLabelProps,
  hideNotRequiredLabel = false,
  ...rest
}: FormItemProps<T>) => {
  const { control } = useFormContext<T>();

  if (viewMode && notRenderView) {
    return null;
  }

  return (
    <Controller
      {...rest}
      name={name}
      rules={rules}
      control={control}
      render={({
        field: { value, onChange, ref },
        fieldState: { invalid, error },
      }) => {
        const helperText = error ? error.message : help;

        if (viewMode) {
          return renderView ? (
            renderView(value, onChange)
          ) : (
            <div
              className={classNames(
                styles.formItemView,
                noMargin && styles.formItemNoMargin,
                helperText && styles.formItemViewHelper
              )}>
              <InfoRow
                label={label}
                value={renderViewValue ? renderViewValue(value) : value}
                noMargin
                tooltip={tooltip}
                tooltipPlacement={
                  tooltipPlacement === 'input' ? 'value' : tooltipPlacement
                }
              />

              {helperText && (
                <FormHelperText component="div">{helperText}</FormHelperText>
              )}
            </div>
          );
        }

        const isRequired = required || !!rules?.required;

        return (
          <div
            className={classNames(
              styles.formItem,
              noMargin && styles.formItemNoMargin,
              className
            )}>
            <FormControl error={invalid}>
              {isControlLabel ? (
                <div className={styles.formItemControl}>
                  <FormControlLabel
                    {...formControlLabelProps}
                    label={
                      tooltip && tooltipPlacement === 'label' ? (
                        <FormItemLabelTooltip title={tooltip} label={label} />
                      ) : (
                        label || ''
                      )
                    }
                    checked={!!value}
                    value={value}
                    control={cloneElement(children, {
                      id: name,
                      inputRef: ref,
                      onChange: (...args: any[]) => {
                        onChange(...args);
                        children.props?.onChange?.(...args);
                      },
                    })}
                  />
                  {tooltip && tooltipPlacement === 'input' && (
                    <FormItemTooltip title={tooltip} />
                  )}
                </div>
              ) : (
                <>
                  {label && (
                    <FormLabel
                      required={showRequiredAsterisk && isRequired}
                      htmlFor={name}
                      className={classNames(
                        tooltip &&
                          tooltipPlacement === 'label' &&
                          styles.flexLabel,
                        error && styles.errorLabel
                      )}
                      error={false}
                      focused={false}>
                      {tooltip && tooltipPlacement === 'label' ? (
                        <FormItemLabelTooltip title={tooltip} label={label} />
                      ) : (
                        label || ''
                      )}
                      {!isRequired && !hideNotRequiredLabel && (
                        <Typography variant="inherit" color="secondary">
                          {' (необязательно)'}
                        </Typography>
                      )}
                    </FormLabel>
                  )}
                  <div className={styles.formItemControl}>
                    <div className={styles.formItemControlInput}>
                      {cloneElement(children, {
                        id: name,
                        inputRef: ref,
                        value,
                        onChange: (...args: any[]) => {
                          onChange(...args);
                          children.props?.onChange?.(...args);
                        },
                      })}
                    </div>
                    {tooltip && tooltipPlacement === 'input' && (
                      <FormItemTooltip title={tooltip} />
                    )}
                    {helperTextPlacement === 'right' && helperText && (
                      <FormHelperText
                        sx={{
                          margin: 0,
                          display: 'flex',
                          alignItems: 'center',
                          pl: 1,
                        }}
                        component="div">
                        {helperText}
                      </FormHelperText>
                    )}
                  </div>
                </>
              )}
              {helperTextPlacement === 'bottom' && helperText && (
                <FormHelperText component="div">{helperText}</FormHelperText>
              )}
            </FormControl>
          </div>
        );
      }}
    />
  );
};
