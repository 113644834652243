import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CompanyTypes,
  ContractDocumentTypes,
  IContract,
  IContractHistory,
  IGetContractHistoryParams,
} from 'src/models';
import { IActiveContract, IContractAddress } from 'src/models/contract';
import { baseQueryWithReauth } from 'src/store/utils';

const BASE_PATH = 'contracts/api';

export const contractApi = createApi({
  reducerPath: 'contractApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'getContractHistoryChanges',
    'getCustomerContract',
    'getContractorContract',
    'contracts',
  ],
  endpoints: (builder) => ({
    getCompanyContract: builder.query<
      IContract[],
      { companyId: number; companyType: CompanyTypes }
    >({
      query: ({ companyId, companyType }) => ({
        url: `${BASE_PATH}/v1/contract/${companyType.toLowerCase()}/${companyId}`,
      }),
      providesTags: ['contracts'],
    }),

    goToNextStep: builder.mutation<
      IContract,
      {
        contractId: number;
        signDate?: string;
        validityEndDate?: string;
        signatoryFio?: string;
        basis?: string;
        nds?: string;
        contractNumber?: string;
      }
    >({
      query: ({ contractId, ...body }) => ({
        url: `${BASE_PATH}/v1/contract/${contractId}/state/next`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          // dispatch(
          //   contractApi.util.updateQueryData(
          //     resultData.contractType === 'CONTRACTOR'
          //       ? 'getContractorContract'
          //       : 'getCustomerContract',
          //     resultData.organizationId,
          //     (data) =>
          //       data.map((contract) =>
          //         resultData.contractId === contract.contractId
          //           ? resultData
          //           : contract
          //       )
          //   )
          // );
        } catch {
          // ignore
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'getContractHistoryChanges', id: arg.contractId },
        { type: 'getCustomerContract' },
        { type: 'getContractorContract' },
        { type: 'contracts' },
      ],
    }),

    goToNextStepContractorSigning: builder.mutation<
      IContract,
      {
        contractId: number;
        signDate?: string;
        validityEndDate?: string;
        signatoryFio?: string;
        basis?: string;
        nds?: string;
        contractNumber?: string;
      }
    >({
      query: ({ contractId, ...body }) => ({
        url: `${BASE_PATH}/v1/contract/${contractId}/contractor-signing/state/next`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          // dispatch(
          //   contractApi.util.updateQueryData(
          //     resultData.contractType === 'CONTRACTOR'
          //       ? 'getContractorContract'
          //       : 'getCustomerContract',
          //     resultData.organizationId,
          //     (data) =>
          //       data.map((contract) =>
          //         resultData.contractId === contract.contractId
          //           ? resultData
          //           : contract
          //       )
          //   )
          // );
        } catch {
          // ignore
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'getContractHistoryChanges', id: arg.contractId },
        { type: 'getCustomerContract' },
        { type: 'getContractorContract' },
        { type: 'contracts' },
      ],
    }),

    goToFirstStep: builder.mutation<IContract, number>({
      query: (contractId) => ({
        url: `${BASE_PATH}/v1/contract/${contractId}/state/first`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getContractHistoryChanges', id: arg },
        { type: 'getCustomerContract' },
        { type: 'getContractorContract' },
        { type: 'contracts' },
      ],
    }),
    uploadDocument: builder.mutation<
      IContract['documents'][ContractDocumentTypes][number],
      {
        id: number;
        documentType: ContractDocumentTypes;
        file: FormData;
      }
    >({
      query: ({ id, documentType, file }) => ({
        url: `${BASE_PATH}/v1/contract/${id}/document`,
        method: 'POST',
        params: { documentType },
        body: file,
      }),
    }),
    removeDocument: builder.mutation<
      IContract,
      { id: number; documentId: number }
    >({
      query: ({ id, documentId }) => ({
        url: `${BASE_PATH}/v1/contract/${id}/document/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getContractHistoryChanges', id: arg.id },
        { type: 'getCustomerContract' },
        { type: 'getContractorContract' },
        { type: 'contracts' },
      ],
    }),

    getContractHistoryChanges: builder.query<
      IContractHistory[],
      IGetContractHistoryParams
    >({
      query: ({ id, direction: directionOfActionAt }) => ({
        url: `${BASE_PATH}/v1/contract/${id}/document/history`,
        params: {
          directionOfActionAt,
        },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getContractHistoryChanges', id: arg.id },
      ],
    }),

    createContract: builder.mutation<IContract, { organizationId: number }>({
      invalidatesTags: [
        'getCustomerContract',
        'getContractorContract',
        'contracts',
      ],
      query: ({ organizationId }) => ({
        url: `${BASE_PATH}/v1/contract`,
        method: 'POST',
        body: { organizationId },
      }),
    }),

    createContractForSingleRole: builder.mutation<
      IContract,
      { organizationId: number; organizationRole: CompanyTypes }
    >({
      invalidatesTags: [
        'getCustomerContract',
        'getContractorContract',
        'contracts',
      ],
      query: (body) => ({
        url: `${BASE_PATH}/v1/contract/single-role`,
        method: 'POST',
        body,
      }),
    }),

    generateContractFromTemplate: builder.mutation<
      void,
      {
        organizationId: number;
        bankDetailsId: number;
        templateId: number;
        draftContractId: number;
        svsSignerId: number;
        kaSignerId: number;
        signDate: string;
        validityEndDate: string;
        nds: string;
        contractType: CompanyTypes;
        documentType: ContractDocumentTypes;
        address: string;
      }
    >({
      invalidatesTags: [
        'getCustomerContract',
        'getContractorContract',
        'contracts',
      ],
      query: (body) => ({
        url: `${BASE_PATH}/v1/contract-template/generate-contract`,
        method: 'POST',
        body,
      }),
    }),

    getOrganizationActiveContracts: builder.query<
      IActiveContract[],
      {
        organizationId: number;
        companyType: CompanyTypes;
      }
    >({
      query: ({ organizationId, companyType }) => ({
        url: `business-requests/api/v1/manager/${
          companyType === CompanyTypes.CUSTOMER ? 'customer' : 'contractor'
        }-specification/contract/${organizationId}`,
      }),
    }),

    getCustomerOrganizationActiveContracts: builder.query<
      IActiveContract[],
      {
        businessApplicationId: number;
        companyType: CompanyTypes;
      }
    >({
      query: ({ businessApplicationId, companyType }) => ({
        url: `business-requests/api/v1/manager/${
          companyType === CompanyTypes.CUSTOMER ? 'customer' : 'contractor'
        }-specification/contracts/${businessApplicationId}`,
      }),
    }),

    getCompanyContractAddresses: builder.query<
      IContractAddress[],
      { organizationId: number; companyType: CompanyTypes }
    >({
      query: ({ organizationId, companyType }) => ({
        url: `contracts/api/v1/contract/${organizationId}/${
          companyType === CompanyTypes.CONTRACTOR ? 'contractor' : 'customer'
        }/address`,
      }),
    }),
  }),
});

export const {
  useGetCompanyContractQuery,
  useGoToNextStepMutation,
  useGoToNextStepContractorSigningMutation,
  useGoToFirstStepMutation,
  useUploadDocumentMutation,
  useRemoveDocumentMutation,
  useCreateContractMutation,
  useLazyGetContractHistoryChangesQuery,
  useGenerateContractFromTemplateMutation,
  useCreateContractForSingleRoleMutation,
  useGetOrganizationActiveContractsQuery,
  useGetCustomerOrganizationActiveContractsQuery,
  useGetCompanyContractAddressesQuery,
} = contractApi;
