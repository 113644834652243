import { AllCompanyRequestsFilterKeys } from 'src/components/widgets/all-company-requests-list/types/all-company-requests-filter-keys';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

export const useAllRequestsListQueryParams = () => {
  const params = useQueryParams({
    field: StringParam,
    order: StringParam,
    [AllCompanyRequestsFilterKeys.KAM_ID]: ArrayParam,
    [AllCompanyRequestsFilterKeys.START_DATE]: StringParam,
    [AllCompanyRequestsFilterKeys.BUDGET]: StringParam,
    [AllCompanyRequestsFilterKeys.STATUS]: ArrayParam,
  });

  return params;
};
