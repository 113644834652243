import { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AddTableItemIcon,
  DocumentTemplatesIcon,
  InfoHelpIcon,
} from 'src/assets/icons';
import {
  Autocomplete,
  Box,
  Button,
  Form,
  FormLabel,
  InfoRow,
  Link,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from 'src/components/ui';
import {
  ConversationEntityType,
  ICargoDescriptions,
  IManagerContractorSpecification,
  IManagerContractorSpecificationAvailableContractor,
  ManagerContractorSpecificationStatus,
} from 'src/models';
import { OpenChat } from 'src/components/widgets';
import { IChangeLogs } from 'src/models/changelogs';
import { IActiveContract } from 'src/models/contract';
import { required } from 'src/utils/form-rules';
import {
  Comment,
  CriteriaSelectingWinner,
  Deadlines,
  FilesInput,
  Finance,
  Logistics,
  PaymentConditions,
  ShowWinnerCostPerformers,
} from '../components';

interface IContractorSpecificationProps {
  viewMode: boolean;
  specification: IManagerContractorSpecification | undefined;
  isCustomerFinalVersionSent: boolean | undefined;
  checkRequired: boolean;
  shippingIncluded: boolean;
  isQuickDeal: boolean;

  logistics: {
    deliveryAddress: string;
    shippingAddress: string;

    cargoDescription: ICargoDescriptions | null;

    costsLogistics: number | string;
    calculatedShippingCost: number;
  };

  orderProcessingStarted: boolean;

  costHistory?: IChangeLogs;
  supplyDurationHistory?: IChangeLogs;
  paymentPeriodProlongationHistory?: IChangeLogs;
  paymentConditionsHistory?: IChangeLogs;
  selectedContractor?: IManagerContractorSpecificationAvailableContractor | null;
  contractorContracts: IActiveContract[];
  isContractsLoading: boolean;
}

const mode = 'contractor';

export const ContractorSpecification: FC<IContractorSpecificationProps> = ({
  viewMode,
  specification,
  isCustomerFinalVersionSent,
  checkRequired,
  shippingIncluded,
  logistics,
  isQuickDeal,
  orderProcessingStarted,
  costHistory,
  supplyDurationHistory,
  paymentPeriodProlongationHistory,
  paymentConditionsHistory,
  selectedContractor,
  contractorContracts,
  isContractsLoading,
}) => {
  const navigate = useNavigate();

  const isSignedScanReceived =
    specification?.status.code ===
    ManagerContractorSpecificationStatus.SIGNED_SCAN_RECEIVED;

  const isFinalVersionSent =
    specification?.status.code ===
    ManagerContractorSpecificationStatus.FINAL_VERSION_SENT;

  const hashInfoLogistics = Boolean(
    logistics?.deliveryAddress &&
      logistics?.shippingAddress &&
      logistics?.cargoDescription?.parameters
  );

  const currentLogisticsHasCalculate =
    Number(logistics?.costsLogistics) ===
    Number(logistics?.calculatedShippingCost);

  const getContractLabel = (contract: IActiveContract) => {
    if (contract.contractNumber && contract.templateName) {
      return `${contract.contractNumber} - ${contract.templateName}`;
    }

    if (contract.contractNumber) {
      return contract.contractNumber;
    }

    if (contract.templateName) {
      return contract.templateName;
    }

    return 'Договор без номера и шаблона';
  };

  return (
    <>
      <Stack
        direction="column"
        gap="8px"
        marginBottom={viewMode ? '0px' : '24px'}>
        <Form.Item
          noMargin
          name="contractData"
          label="Договор"
          viewMode={viewMode}
          rules={{
            required: checkRequired ? required : undefined,
          }}
          renderView={(item) => (
            <InfoRow
              label="Договор"
              value={
                item ? (
                  <Link
                    component={RouterLink}
                    to={`/companies/contractors/${selectedContractor?.organizationId}/contract/${item.contractId}`}>
                    {getContractLabel(item)}
                  </Link>
                ) : undefined
              }
            />
          )}>
          <Autocomplete<IActiveContract>
            disabled={
              !contractorContracts ||
              contractorContracts.length === 0 ||
              isContractsLoading
            }
            getOptionLabel={(option) => getContractLabel(option)}
            options={contractorContracts}
            fetchApi={(value) =>
              Promise.resolve(
                value
                  ? contractorContracts.filter(
                      (contract) =>
                        contract.contractNumber?.includes(value) ||
                        contract.templateName?.includes(value)
                    )
                  : contractorContracts
              )
            }
            fetchOnEmptyInput
            placeholder="Выберите договор из списка"
          />
        </Form.Item>

        {!viewMode &&
          selectedContractor &&
          !isContractsLoading &&
          contractorContracts.length === 0 && (
            <FormLabel error>У компании нет ни одного договора</FormLabel>
          )}

        {!viewMode && selectedContractor && (
          <Stack direction="row" alignItems="center" gap="20px">
            {contractorContracts.length !== 0 && (
              <Button
                variant="text"
                applyNewIconSizing
                startIcon={<DocumentTemplatesIcon />}
                onClick={() =>
                  navigate(
                    `/companies/contractors/${
                      selectedContractor!.organizationId
                    }/contract`
                  )
                }>
                Перейти к договорам
              </Button>
            )}
            <Button
              variant="text"
              applyNewIconSizing
              startIcon={<AddTableItemIcon />}
              onClick={() =>
                navigate(
                  `/companies/contractors/${
                    selectedContractor!.organizationId
                  }/about?mode=addContract`
                )
              }>
              Создать новый
            </Button>
          </Stack>
        )}
      </Stack>

      <Finance
        mode={mode}
        viewMode={viewMode}
        checkRequired={checkRequired}
        shippingIncluded={shippingIncluded}
        costsLogisticsIsManually={
          !currentLogisticsHasCalculate ||
          (Number(logistics.costsLogistics) > 0 && !hashInfoLogistics)
        }
        costHistory={costHistory}
      />

      {shippingIncluded && <Logistics />}

      <Deadlines
        viewMode={viewMode}
        checkRequired={checkRequired}
        supplyDurationHistory={supplyDurationHistory}
        paymentPeriodProlongationHistory={paymentPeriodProlongationHistory}
        mode={mode}
      />

      <PaymentConditions
        paymentConditionsHistory={paymentConditionsHistory}
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
      />

      {specification?.contractorRequestId && !orderProcessingStarted && (
        <OpenChat
          entityType={ConversationEntityType.CONTRACTOR_REQUEST}
          entityId={specification.contractorRequestId}
          mode="manager"
          sx={{ marginBottom: '24px' }}
        />
      )}

      <FilesInput
        specification={specification}
        isCustomerFinalVersionSent={isCustomerFinalVersionSent}
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
        isQuickDeal={isQuickDeal}
      />

      {(isFinalVersionSent || isSignedScanReceived || isQuickDeal) &&
        (isCustomerFinalVersionSent ? (
          <>
            {!isFinalVersionSent && (
              <Box mb="24px">
                <Typography color="secondary">
                  После отправки финальной спецификации победителю, другие
                  исполнители получат оповещение о том, что их КП перенесены в
                  резерв. Проигравшие исполнители увидят критерии выбора и
                  комментарий.
                </Typography>
              </Box>
            )}

            <CriteriaSelectingWinner
              viewMode={isQuickDeal ? viewMode : isFinalVersionSent}
            />

            <Comment viewMode={isQuickDeal ? viewMode : isFinalVersionSent} />

            <ShowWinnerCostPerformers
              viewMode={isQuickDeal ? viewMode : isFinalVersionSent}
            />
          </>
        ) : (
          <Typography
            color="secondary"
            mb="24px"
            sx={{ '& > div': { display: 'inline' } }}>
            Вы не можете отправить финальную спецификацию&nbsp;
            <Tooltip title="спецификация, которая подписана с обеих сторон">
              <SvgIcon icon={InfoHelpIcon} width="16px" height="16px" />
            </Tooltip>
            &nbsp;исполнителю до тех пор, пока не отправлена финальная
            спецификация заказчику
          </Typography>
        ))}
    </>
  );
};
