import { TListRequestParams } from 'src/api/models';
import { DIRECTION } from 'src/components/ui/filters/types';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { ContractStatuses } from 'src/models/contract';
import { BusinessRequestSource, ISpecificRole } from 'src/models/bids-details';
import { ROLES } from 'src/constants';

export interface IBid {
  id: number;
  orderNumber: string;
  label: string;
  kam: string;
  activityName: string[];
  companyName: string;
  date: string;

  statusContract: ContractStatuses;

  incomingBids: number;
  completedBidsGray: number;
  completedBids: number;
  inProgressBids: number;
  inProgressBidsRed: number;
}

export type TContractorBusinessRequestList = {
  totalCount: number;
  countByStatusCategory: {
    activeCount: number;
    archivedCount: number;
  };
  page: number;

  businessRequests: {
    auction?: {
      auctionStartDate: string;
      auctionEndDate: string;
    };

    id: number;
    number: string;
    name: string;
    technicalOpportunitiesCategories: string[];
    assignedKam?: {
      userId: number;
      fio: string;
    };
    createdAt: string;
  }[];
};

export type TCustomerRequestList = {
  totalCount: number;
  countByStatusCategories: {
    activeCount: number;
    archivedCount: number;
  };
  items: {
    statusName: string;
    statusCode: string;

    businessRequests: {
      id: number;
      number: string;
      name: string;
      commercialOfferCost: number;
      assignedKam?: {
        userId: number;
        fio: string;
      };
      createdAt: string;
    }[];
  }[];
};

export interface ISpecificRoleKanbanBusinessRequest {
  countByStatus: {
    activeCount: number;
    resolved: number;
    totalCount: number;
  } | null;

  items: {
    statusCode: SpecificRoleStatusCode;
    statusName: string;
    businessRequestsCountByStatus: number | null;

    businessRequests: {
      businessRequestId: number;
      number: number;
      name: string;
      customerOrganizationId: number;
      technicalOpportunitiesCategories: number[];
      createdAt: string;
      color: string;

      assignedManager: {
        managerType: 'KAM' | 'KAM_PLUS';
        firstName: string;
        lastName: string;
        middleName: string;
      } | null;

      businessRequestStatistics: {
        commercialOfferByContractorsTotalCount: number;
        totalRefusedContractorRequestsCount: number;
        contractorRequestsInProgressCount: number;
        unclaimedContractorRequestsCount: number;
        contractorRequestsSentCount: number;
        daysSinceStatusChangeCount: number;
      };
    }[];
  }[];
}

interface INotViewedCommunication {
  count: number;
  color: 'RED' | 'BLUE' | 'GRAY';
}

export interface IManagerKanbanBusinessRequest {
  countByStatus: {
    activeCount: number;
    expectedCount: number;
    archivedCount: number;
    resolvedCount?: number;
    totalCount: number;
  } | null;

  items: {
    statusCode: KamStatusCode;
    statusName: string;
    businessRequestsCountByStatus: number | null;
    budgetSumByStatus: number;

    businessRequests: {
      businessRequestId: number;
      number: number;
      name: string;
      customerOrganizationId: number;
      technicalOpportunitiesCategories: number[];
      createdAt: string;
      color: string;
      budgetAmount?: number;

      assignedManager: {
        managerType: 'KAM' | 'KAM_PLUS';
        firstName: string;
        lastName: string;
        middleName: string;
      } | null;

      businessRequestStatistics: {
        commercialOfferByContractorsTotalCount: number;
        totalRefusedContractorRequestsCount: number;
        contractorRequestsInProgressCount: number;
        unclaimedContractorRequestsCount: number;
        contractorRequestsSentCount: number;
        daysSinceStatusChangeCount: number;
      };

      repeatedType: string;

      assignedSpecificRoles: ISpecificRole[] | null;

      notViewedCommunications: {
        emails: INotViewedCommunication;
        calls: INotViewedCommunication;
        notes: INotViewedCommunication;
        messages: INotViewedCommunication;
      };
    }[];
  }[];
}

export type TTechnicalOpportunitiesCategories = {
  categoryId: number;
  subcategoryIds: number[];
}[];

export const isTags = (
  tags: any
): tags is TTechnicalOpportunitiesCategories => {
  if (Array.isArray(tags)) {
    let allElementsHaveAttributes = true;

    tags.forEach((tag) => {
      if (
        !(
          tag &&
          typeof tag === 'object' &&
          'categoryId' in tag &&
          'subcategoryIds' in tag
        )
      ) {
        allElementsHaveAttributes = false;
      }
    });
    return allElementsHaveAttributes;
  }
  return false;
};

export interface IManagerKanbanBusinessRequestFilterParams {
  statusGroup: string;
  isAssigned: boolean;
  technicalOpportunitiesCategories: TTechnicalOpportunitiesCategories;
  startDate: string;
  endDate: string;
  tenderStartDate: string;
  tenderEndDate: string;
  saleStartDate: string;
  saleEndDate: string;
  requestStatuses: KamStatusCode[];
  creationSources: BusinessRequestSource[];
  kamUserIds: string[];
  kamPlusUserIds: string[];
  organizationIds: string[];
  minBudget: string;
  maxBudget: string;
  numbers: string[];
}

export interface IManagerKanbanBusinessRequestParams {
  filter: IManagerKanbanBusinessRequestFilterParams;

  sort: {
    field: string;
    order: DIRECTION;
  };

  searchQueries: {
    query: string;
    categoryIds: number[];
  }[];

  paging: {
    vertical: {
      page: number;
      limit: number;
    };
  };
}

export interface IRequestNumberWithId {
  businessApplicationId: number;
  businessApplicationNumber: number;
}

export interface IManagerKanbanLastActivityBusinessRequest {
  businessRequestId: number;
  commercialOfferByContractorsDelta: number | null;
  refusedContractorRequestsDelta: number | null;
}

export interface IManagerKanbanLastActivityBusinessRequestParams {
  fromDate: string;
  businessRequestIds: number[];
}

export interface IManagerKanbanCostIndicatorStatistics {
  businessRequestId: number;
  costIndicator: number;
  isBestCost: boolean;
}

export interface IStatusHistory<T = string> {
  passedStatuses: {
    statusHistoryId: number;
    status: {
      code: T;
      displayName: string;
      description: string;
    };
    createdAt: string;
  }[];

  upcomingStatuses: {
    code: T;
    displayName: string;
    description: string;
  }[];
}

export interface IContractorRequestList {
  totalCount: number;

  countByStatusCategories: {
    inProgressCount: number;
    invitedCount: number;
    auctionCount: number;
    archivedCount: number;
  };

  items: {
    statusName: string;
    statusCode: string;

    contractorRequests: {
      id: number;
      number: number;
      name: string;
      technicalOpportunitiesCategories: [];
      createdAt: string;

      commercialOfferCost: number | null;
      hasCommercialOfferWithBestPrice: boolean;
      assignedKam?: {
        userId: number;
        fio: string;
      };
    }[];
  }[];
}

export interface IBusinessRequestAuctionDateStatistics {
  businessRequestId: number;
  auctionStartDate: string;
  auctionEndDate: string;
}

export interface IContractorRequestListDto {
  filter: {
    statusCategory: string;
  };
  sort: {
    field: string;
    order: DIRECTION;
  };
  searchQueries: string[];
}

export enum BusinessRequestMode {
  MANAGER = 'MANAGER',
  CUSTOMER = 'CUSTOMER',
  CONTRACTOR = 'CONTRACTOR',
  CONTRACTOR_ALL = 'CONTRACTOR_ALL',
  SPECIFIC_ROLE = 'SPECIFIC_ROLE',
}

export enum KamStatusCode {
  NEW_REQUEST = 'NEW_REQUEST',
  COMMERCIAL_QUALIFICATION = 'COMMERCIAL_QUALIFICATION',
  TECHNICAL_QUALIFICATION = 'TECHNICAL_QUALIFICATION',
  QUALIFIED = 'QUALIFIED',
  SENT_TO_CONTRACTORS = 'SENT_TO_CONTRACTORS',
  AUCTION_IN_PROGRESS = 'AUCTION_IN_PROGRESS',
  REBIDDING_IN_PROGRESS = 'REBIDDING_IN_PROGRESS',
  COMMERCIAL_OFFER_RECEIVED = 'COMMERCIAL_OFFER_RECEIVED',
  NO_BEST_COMMERCIAL_OFFER = 'NO_BEST_COMMERCIAL_OFFER',
  BEST_COMMERCIAL_OFFER_RECEIVED = 'BEST_COMMERCIAL_OFFER_RECEIVED',
  COMMERCIAL_OFFER_SENT_TO_CUSTOMER = 'COMMERCIAL_OFFER_SENT_TO_CUSTOMER',
  COMMERCIAL_OFFER_REFINED = 'COMMERCIAL_OFFER_REFINED',
  COMMERCIAL_OFFER_REJECTED = 'COMMERCIAL_OFFER_REJECTED',
  SPECIFICATION_SENT = 'SPECIFICATION_SENT',
  SPECIFICATION_SIGNED = 'SPECIFICATION_SIGNED',
  IN_PRODUCTION = 'IN_PRODUCTION',
  DELIVERED = 'DELIVERED',
  CLOSED = 'CLOSED',
  REQUEST_REFUSAL = 'REQUEST_REFUSAL',
}

export const KamStatusCodeOrderMap: Record<KamStatusCode, number> = {
  NEW_REQUEST: 0,
  COMMERCIAL_QUALIFICATION: 1,
  TECHNICAL_QUALIFICATION: 2,
  QUALIFIED: 3,
  SENT_TO_CONTRACTORS: 4,
  AUCTION_IN_PROGRESS: 5,
  REBIDDING_IN_PROGRESS: 6,
  COMMERCIAL_OFFER_RECEIVED: 7,
  NO_BEST_COMMERCIAL_OFFER: 8,
  BEST_COMMERCIAL_OFFER_RECEIVED: 9,
  COMMERCIAL_OFFER_SENT_TO_CUSTOMER: 10,
  COMMERCIAL_OFFER_REFINED: 11,
  COMMERCIAL_OFFER_REJECTED: 12,
  SPECIFICATION_SENT: 13,
  SPECIFICATION_SIGNED: 14,
  IN_PRODUCTION: 15,
  DELIVERED: 16,
  CLOSED: 17,
  REQUEST_REFUSAL: 18,
};

export enum SpecificRoleStatusCode {
  REQUEST_AGREEMENT = 'REQUEST_AGREEMENT',
  REQUEST_FORMULATION = 'REQUEST_FORMULATION',
  QUOTATION_AGREEMENT = 'QUOTATION_AGREEMENT',
  // QUOTATION_LOGISTICS_CALCULATION = 'QUOTATION_LOGISTICS_CALCULATION',
  SPECIFICATION_AGREEMENT = 'SPECIFICATION_AGREEMENT',
  // SPECIFICATION_LOGISTICS_CALCULATION = 'SPECIFICATION_LOGISTICS_CALCULATION',
  LOGISTICS_NEW_REQUEST = 'LOGISTICS_NEW_REQUEST',
  LOGISTICS_IN_PROGRESS = 'LOGISTICS_IN_PROGRESS',
  LOGISTICS_COST_PROVIDED = 'LOGISTICS_COST_PROVIDED',
  SPECIFICATION_NEW_REQUEST = 'SPECIFICATION_NEW_REQUEST',
  SPECIFICATION_FORMING = 'SPECIFICATION_FORMING',
  SPECIFICATION_SENT = 'SPECIFICATION_SENT',
  SPECIFICATION_SIGNED_BY_COUNTERPARTY = 'SPECIFICATION_SIGNED_BY_COUNTERPARTY',
  SPECIFICATION_SIGNED = 'SPECIFICATION_SIGNED',
}

export enum ContractorStatusCode {
  NEW_REQUEST = 'NEW_REQUEST',
  IN_PROGRESS = 'IN_PROGRESS',
  COMMERCIAL_OFFER_SENT = 'COMMERCIAL_OFFER_SENT',
  AUCTION_IN_PROGRESS = 'AUCTION_IN_PROGRESS',
  REBIDDING_IN_PROGRESS = 'REBIDDING_IN_PROGRESS',
  SPECIFICATION_APPROVAL = 'SPECIFICATION_APPROVAL',
  SPECIFICATION_SIGNED = 'SPECIFICATION_SIGNED',
  SPECIFICATION_SIGNED_WITH_ANOTHER_CONTRACTOR = 'SPECIFICATION_SIGNED_WITH_ANOTHER_CONTRACTOR',
  IN_PRODUCTION = 'IN_PRODUCTION',
  DELIVERED = 'DELIVERED',
  CLOSED = 'CLOSED',
  REQUEST_REFUSAL = 'REQUEST_REFUSAL',
  REQUEST_EXCLUSION = 'REQUEST_EXCLUSION',
}

export enum CustomerStatusCode {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMMERCIAL_OFFER_RECEIVED = 'COMMERCIAL_OFFER_RECEIVED',
  SPECIFICATION_APPROVAL = 'SPECIFICATION_APPROVAL',
  SPECIFICATION_SIGNED = 'SPECIFICATION_SIGNED',
  IN_PRODUCTION = 'IN_PRODUCTION',
  DELIVERED = 'DELIVERED',
  CLOSED = 'CLOSED',
  REQUEST_REFUSAL = 'REQUEST_REFUSAL',
}

export interface IContractorRequestKanbanParams {
  filter: {
    statusGroup: string;

    technicalOpportunitiesCategories: TTechnicalOpportunitiesCategories;
  };

  sort: {
    field: string;
    order: DIRECTION;
  };

  searchQueries: {
    query: string;
    categoryIds: number[];
  }[];

  paging: {
    vertical: {
      page: number;
      limit: number;
    };
  };
}

export interface IContractorRequestKanban {
  countByStatus: {
    activeCount: number;
    archivedCount: number;
    totalCount: number;
  } | null;

  items: {
    statusCode: ContractorStatusCode;
    statusName: string;
    contractorRequestsCountByStatus: number | null;

    contractorRequests: {
      contractorRequestId: number;
      number: number;
      name: string;
      technicalOpportunitiesCategories: number[];
      createdAt: string;

      assignedManager: {
        managerType: 'KAM' | 'KAM_PLUS';
        firstName: string;
        lastName: string;
        middleName: string;
      } | null;
    }[];
  }[];
}

export interface IContractorRequestKanbanCostIndicatorStatistics {
  contractorRequestId: number;
  costIndicator: number;
  isBestCost: boolean;
}

export interface IContractorRequestKanbanAuctionDateStatistics {
  contractorRequestId: number;
  auctionStartDate: string;
  auctionEndDate: string;
}

export interface ICustomerRequestKanban {
  countByStatus: {
    activeCount: number;
    archivedCount: number;
    totalCount: number;
  } | null;

  items: {
    statusCode: CustomerStatusCode;
    statusName: string;
    businessRequestsCountByStatus: number;

    businessRequests: {
      businessRequestId: number;
      number: number;
      name: string;
      customerOrganizationId: number;
      technicalOpportunitiesCategories: number[];
      createdAt: string;

      assignedManager: {
        managerType: 'KAM' | 'KAM_PLUS';
        firstName: string;
        lastName: string;
        middleName: string;
      } | null;

      notViewedCommunications: {
        emails: INotViewedCommunication;
        calls: INotViewedCommunication;
        notes: INotViewedCommunication;
        messages: INotViewedCommunication;
      };
    }[];
  }[];
}

export interface ICustomerRequestKanbanParams {
  filter: {
    statusGroup: string;
    technicalOpportunitiesCategories: TTechnicalOpportunitiesCategories;
  };
  sort: {
    field: string;
    order: DIRECTION;
  };
  searchQueries: {
    query: string;
    categoryIds: number[];
  }[];

  paging: {
    vertical: {
      page: number;
      limit: number;
    };
  };
}

export interface ICustomerRequestKanbanCostIndicatorStatistics {
  businessRequestId: number;
  costIndicator: number;
}

export type TGetAllBusinessRequestsByContractorResponseItem = {
  businessRequestId: 0;
  businessRequestNumber: 0;
  businessRequestName: string;
  businessRequestCreationDate: string;
  assignedKam: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  technicalOpportunitiesCategories: number[];
  commercialOfferDuedate: string;
};

export type TGetAllBusinessRequestsByContractorResponse = {
  businessRequests: TGetAllBusinessRequestsByContractorResponseItem[];
  countByStatusCategory: {
    activeCount: number;
    archivedCount: number;
  };
};

export type TGetAllBusinessRequestsByContractorParams = Omit<
  Required<
    TListRequestParams<
      'CREATED_AT',
      {
        statusGroup: string;
        technicalOpportunitiesCategories: {
          categoryId: number;
          subcategoryIds: number[];
        }[];
      },
      {
        query: string;
        categoryIds?: number[];
      }[]
    >
  >,
  'pagingDto' | 'sortDto' | 'isAssigned'
> & {
  paging: TListRequestParams['pagingDto'];
};

export type TCompanyBusinessRequest = {
  statusForKam: string;
  number: number;
  businessRequestId: number;
  createdAt: string;
  name: string;
  budget: string;
  tagClassifierFistLevel: string[];
  applicationStatusHistory: number;
  fullName: {
    firstName: string;
    lastName: string;
    middleName: string;
    phones: TPhoneInputValue[];
  };
  type: 'EXPECTED' | 'REAL';
  statusForKamValue: KamStatusCode;
};

export interface ISaleDateHistoryItem {
  id: number;
  businessApplicationId: number;
  saleDate: string;
  user: {
    userId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    userRole: ROLES;
  };
  createdAt: string;
}

export type ISaleDateHistory = ISaleDateHistoryItem[];
