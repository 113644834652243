import {
  Alert,
  Typography,
  Stack,
  DialogActions,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Box,
} from 'src/components/ui';
import { TVariantBusinessRequest } from 'src/models/bids-details';
import { formatServerDate, joinFullName } from 'src/utils';
import { AuthService } from 'src/services';
import { Check } from 'src/assets/icons';
import { useState } from 'react';
import {
  useApprovalRefusalRequestMutation,
  useDiscardRefusalRequestMutation,
} from 'src/api/business-request/business-request';
import styles from './approval-expecting.module.scss';

interface IApprovalExpectingProps {
  businessRequestId: number;
  applicationId: number;
  businessRequest: TVariantBusinessRequest;
}

export const ApprovalExpecting = ({
  businessRequestId,
  applicationId,
  businessRequest,
}: IApprovalExpectingProps) => {
  const isNeedMyApprove =
    AuthService.userId === businessRequest.refusal?.refusalApprovingUser?.id;
  const [showRefusalRejectModal, setShowRefusalRejectModal] = useState(false);
  const [approvalRefusalRequest, { isLoading: isApprovalLoading }] =
    useApprovalRefusalRequestMutation();
  const [discardRefusalRequest, { isLoading: isDiscardLoading }] =
    useDiscardRefusalRequestMutation();

  const handleCloseModal = () => {
    setShowRefusalRejectModal(false);
  };

  const handleApprovalRefusalRequest = () => {
    approvalRefusalRequest({
      applicationId,
      businessRequestId,
    }).then(handleCloseModal);
  };

  const handleDiscardRefusalRequest = () => {
    discardRefusalRequest({
      applicationId,
      businessRequestId,
    }).then(handleCloseModal);
  };

  if (!businessRequest.refusal) {
    return null;
  }

  if (isNeedMyApprove) {
    return (
      <>
        <Typography
          color="primary"
          className={styles.actionButton}
          fontSize="14px">
          <Stack
            onClick={() => setShowRefusalRejectModal(true)}
            direction="row"
            gap="4px"
            alignItems="center">
            <Check />
            Cогласовать отклонение
          </Stack>
        </Typography>
        <Dialog
          open={showRefusalRejectModal}
          fullWidth
          width="600px"
          close={handleCloseModal}>
          <DialogTitle>Согласование отклонения заявки</DialogTitle>
          <DialogContent>
            <Stack direction="column" gap="20px">
              {businessRequest.refusal.internalCause && (
                <Box>
                  <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                    Причина внутреннего отказа
                  </Typography>
                  <Typography variant="body1" fontSize="14px">
                    {businessRequest.refusal.internalCause}
                  </Typography>
                </Box>
              )}
              {businessRequest.refusal.internalCause && (
                <Box>
                  <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                    Внутренний комментарий
                  </Typography>
                  <Typography variant="body1" fontSize="14px">
                    {businessRequest.refusal.internalComment}
                  </Typography>
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleApprovalRefusalRequest}
              disabled={isApprovalLoading || isDiscardLoading}
              autoFocus>
              Подтвердить отклонение
            </Button>
            <Button
              onClick={handleDiscardRefusalRequest}
              color="secondary"
              disabled={isApprovalLoading || isDiscardLoading}>
              Отменить отклонение
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <Alert
        mode="WARNING"
        content={<>Согласование отклонения</>}
        tooltip={
          <>
            <Typography variant="body2" color="#9FA8B3" fontSize="12px">
              Дата направления на согласование:
            </Typography>
            <Typography variant="body1" fontWeight={500} marginBottom="12px">
              {formatServerDate(
                businessRequest.refusal.createdAt,
                'dd.MM.yyyy HH:mm'
              )}
            </Typography>
            {businessRequest.refusal.internalCause && (
              <>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Внутренняя причина отказа:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  marginBottom="12px">
                  {businessRequest.refusal.internalCause}
                </Typography>
              </>
            )}
            {businessRequest.refusal.cause && (
              <>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Причина:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  marginBottom="12px">
                  {businessRequest.refusal.cause}
                </Typography>
              </>
            )}
            {businessRequest.refusal.internalComment && (
              <>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Внутренний комментарий:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  marginBottom="12px">
                  {businessRequest.refusal.internalComment}
                </Typography>
              </>
            )}
            {businessRequest.refusal.comment && (
              <>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Комментарий:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  marginBottom="12px">
                  {businessRequest.refusal.comment}
                </Typography>
              </>
            )}
            {businessRequest.refusal.customerCause && (
              <>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Причина отказа для заказчика:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  marginBottom="12px">
                  {businessRequest.refusal.customerCause}
                </Typography>
              </>
            )}
            {businessRequest.refusal.customerComment && (
              <>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Комментарий Заказчику:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  marginBottom="12px">
                  {businessRequest.refusal.customerComment}
                </Typography>
              </>
            )}
            {businessRequest.refusal.contractorCause && (
              <>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Причина отказа для исполнителя:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  marginBottom="12px">
                  {businessRequest.refusal.contractorCause}
                </Typography>
              </>
            )}
            <Typography variant="body2" color="#9FA8B3" fontSize="12px">
              Ожидает согласования от:
            </Typography>
            <Typography variant="body1" fontWeight={500}>
              {joinFullName(
                businessRequest.refusal.refusalApprovingUser?.lastName,
                businessRequest.refusal.refusalApprovingUser?.firstName,
                businessRequest.refusal.refusalApprovingUser?.middleName
              )}
            </Typography>
            {!!businessRequest.refusal.refusalApprovingUser?.position
              .length && (
              <Typography variant="body2" color="#9FA8B3" fontSize="10px">
                {businessRequest.refusal.refusalApprovingUser.position}
              </Typography>
            )}
          </>
        }
      />
    </div>
  );
};
