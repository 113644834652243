import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Tooltip, Typography } from 'src/components/ui';
import { InfoHelpIcon } from 'src/assets/icons';
import styles from './alert.module.scss';

export const Alert: FC<{
  content: ReactNode;
  tooltip?: ReactNode;
  className?: string;
  mode?: 'INFO' | 'SUCCESS' | 'ERROR' | 'WARNING';
  fitBorderToMode?: boolean;
  fitTextToMode?: boolean;
}> = ({
  content,
  tooltip,
  className,
  mode = 'ERROR',
  fitBorderToMode = false,
  fitTextToMode = false,
}) => (
  <div
    className={classNames(
      styles[`textWrapper${mode}`],
      fitBorderToMode && styles[`textWrapperBorder${mode}`],
      fitTextToMode && styles[`textWrapperText${mode}`],
      className
    )}>
    <Typography variant="body2">{content}</Typography>

    {tooltip && (
      <Tooltip title={tooltip} placement="bottom-start">
        <InfoHelpIcon />
      </Tooltip>
    )}
  </div>
);
