import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAssignedManagersListMutation } from 'src/api/admin/company';
import { useGetCompanyBusinessRequestsMutation } from 'src/api/business-request/business-request';
import { NewTable, TableSkeleton } from 'src/components/ui';
import { DIRECTION, TSort } from 'src/components/ui/filters/types';
import { TFilterListValuesMap } from 'src/components/ui/new-filters/types';
import { getFiltersList } from 'src/components/widgets/all-company-requests-list/configs/get-filters-list';
import { useAllRequestsListQueryParams } from 'src/components/widgets/all-company-requests-list/hooks/use-all-requests-list-query-params';
import { AllCompanyRequestsFilterKeys } from 'src/components/widgets/all-company-requests-list/types/all-company-requests-filter-keys';
import { AllCompanyRequestsFiltersData } from 'src/components/widgets/all-company-requests-list/types/all-company-requests-filters-data';
import { getFilterVariantsData } from 'src/components/widgets/all-company-requests-list/utils/get-filter-variants-data';
import { PageHeader } from 'src/components/widgets/page-header';
import { TCompanyBusinessRequest } from 'src/models/bid';
import { TGetAllCompanyRequestsParams } from 'src/models/bids-details';
import { initializeSortList } from 'src/utils';
import { decodeFilterRangeValue } from 'src/components/ui/new-filters/components/filters-popover/utils';
import { resetDateTime } from 'src/utils/reset-date-time';
import { columnsConfig } from './columns';
import { sortList } from './configs/sort-list';
import styles from './all-company-requests-list.module.scss';
import { TSortKey } from './types/sort-key';

export const AllCompanyRequestsList = ({
  companyId,
}: {
  companyId: number;
}) => {
  const [queryParams, setQueryParams] = useAllRequestsListQueryParams();

  const [filtersData, setFiltersData] = useState<
    TFilterListValuesMap<AllCompanyRequestsFilterKeys>
  >({});
  const [filterVariantsData, setFilterVariantsData] = useState<
    AllCompanyRequestsFiltersData | undefined
  >(undefined);
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);

  const [getAssignedManagers] = useGetAssignedManagersListMutation();

  useEffect(() => {
    getAssignedManagers({})
      .unwrap()
      .then((res) => {
        const filterVariants = getFilterVariantsData(res);
        setFilterVariantsData(filterVariants);
        setIsFiltersLoading(false);
      });
  }, []);

  const params = useMemo<TGetAllCompanyRequestsParams>(
    () => ({
      filter: {
        kamUserIds: (queryParams?.kams as string[]) || [],
        status: (queryParams.status as string[]) || [],
        firstStartDate: resetDateTime(
          decodeFilterRangeValue(queryParams.startDate).from
        ),
        lastStartDate: resetDateTime(
          decodeFilterRangeValue(queryParams.startDate).to,
          true
        ),
        minBudget: decodeFilterRangeValue(queryParams.budget).from,
        maxBudget: decodeFilterRangeValue(queryParams.budget).to,
      },
      sort: {
        field: queryParams?.field || 'STATUS_FOR_KAM',
        order: (queryParams?.order as DIRECTION) || DIRECTION.ASC,
      },
    }),
    [queryParams]
  );

  const sortListWithSelected = useMemo(
    () =>
      !queryParams.field
        ? sortList
        : initializeSortList({
            sortList,
            fieldName: params.sort?.field,
            order: params.sort?.order as DIRECTION,
          }),
    [queryParams.field, params.sort?.field, params.sort?.order]
  );

  const handleSort = useCallback((selectedSort: TSort<TSortKey> | null) => {
    setQueryParams(
      {
        field: selectedSort?.field,
        order: selectedSort?.direction,
      },
      'pushIn'
    );
  }, []);

  const [getAllCompanyRequests, { data, isLoading, error }] =
    useGetCompanyBusinessRequestsMutation();

  useEffect(() => {
    getAllCompanyRequests({ organizationId: companyId, ...params });
  }, [params]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      width="100%"
      minWidth={0}>
      <Box sx={{ background: '#fff', borderRadius: '12px' }}>
        <PageHeader<TSortKey, unknown, AllCompanyRequestsFilterKeys>
          title="Все заявки компании"
          totalCountLabel="Всего заявок"
          compactVersion
          totalCount={data?.length || 0}
          sortList={sortListWithSelected}
          onSort={handleSort}
          isFiltersLoading={isFiltersLoading}
          filtersData={filtersData}
          filterVariantsList={getFiltersList(filterVariantsData)}
          queryParamsConfig={{
            queryParams,
            setQueryParams: (newParams) => setQueryParams(newParams, 'pushIn'),
          }}
          onFilter={setFiltersData}
        />
      </Box>
      {(isLoading || !data) && !error ? (
        <TableSkeleton />
      ) : (
        !error && (
          <Box
            className={styles.scroll}
            sx={{
              borderRadius: '12px',
              // Todo: убрать костыль с maxHeight, когда у запроса появится пагинация
              maxHeight: '70vh !important',
              overflowY: 'scroll',
            }}>
            <NewTable<TCompanyBusinessRequest & { key: number }>
              className={styles.table}
              columns={columnsConfig}
              page={1}
              countPerPage={Infinity}
              data={data!.map((item) => ({
                ...item,
                key: item.number,
              }))}
            />
          </Box>
        )
      )}
    </Box>
  );
};
