import { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Form,
} from 'src/components/ui';
import { IManagerRejectApplicationParams } from 'src/models';
import {
  useGetManagerRejectApplicationReasonsQuery,
  useSetManagerRejectApplicationQuery,
} from 'src/api/business-request/business-request';
import { getErrorMessage } from 'src/store/utils';
import { DialogSkeleton } from 'src/components/ui/skeleton';
import {
  ReasonsContractor,
  ReasonsCustomer,
  ReasonsManager,
} from './components';
import styles from './manager-reject-application.module.scss';
import { requestBodyRejectApplication } from '../constants';
import { IManagerRejectApplicationForm } from './types';

interface IManagerRejectApplicationProps {
  isShowModalWindow: boolean;
  handleShowModalWindow: () => void;
  businessApplicationId: number;
  businessRequestId: number;
  isCustomerBusinessApplicationRefusal?: boolean;
}

export const ManagerRejectApplication: FC<IManagerRejectApplicationProps> = ({
  isShowModalWindow,
  handleShowModalWindow,
  businessApplicationId,
  businessRequestId,
  isCustomerBusinessApplicationRefusal,
}) => {
  const {
    data: listReasonsData,
    isLoading: isLoadingListReasons,
    error: errorListReasons,
  } = useGetManagerRejectApplicationReasonsQuery(requestBodyRejectApplication);

  const [
    setManagerRejectApplicationQuery,
    { isLoading: isLoadingRejectApplication, error: errorRejectApplication },
  ] = useSetManagerRejectApplicationQuery(
    Boolean(isCustomerBusinessApplicationRefusal)
  )();

  const listReasons = useMemo(() => {
    if (!listReasonsData) return null;

    const filterReasons = listReasonsData.items.filter(
      (item) => item.status !== 'ARCHIVED'
    );

    const nameForCustomer = filterReasons
      .map((element) => element.nameForCustomer)
      .filter(
        (item, index) =>
          filterReasons.findIndex(
            (element) => element.nameForCustomer === item
          ) === index
      );

    const nameForContractor = filterReasons
      .map((element) => element.nameForContractor)
      .filter(
        (item, index) =>
          filterReasons.findIndex(
            (element) => element.nameForContractor === item
          ) === index
      );

    return {
      nameForManager: filterReasons,
      nameForCustomer,
      nameForContractor,
    };
  }, [listReasonsData]);

  const form = useForm<IManagerRejectApplicationForm>({
    defaultValues: {
      managerSelectedReason: null,
      managerComment: '',

      customerSelectedReason: null,
      customerWriteReason: '',
      customerComment: '',
      customerIsWriteReason: false,

      contractorSelectedReason: null,
      contractorWriteReason: '',
      contractorComment: '',
      contractorIsWriteReason: false,
    },
  });

  const currentManagerSelectedReason = form.watch('managerSelectedReason');

  const handleKamReasonsChanged = () => {
    const { managerSelectedReason } = form.getValues();
    form.setValue(
      'customerSelectedReason',
      managerSelectedReason?.nameForCustomer ?? null
    );
    form.setValue(
      'contractorSelectedReason',
      managerSelectedReason?.nameForContractor ?? null
    );
  };

  const handleCustomerReasonsChanged = () => {
    const {
      managerSelectedReason,
      contractorSelectedReason,
      customerSelectedReason,
    } = form.getValues();
    if (!managerSelectedReason && !contractorSelectedReason) {
      const newReason = listReasons?.nameForManager.find(
        (elem) => elem.nameForCustomer === customerSelectedReason
      );
      form.setValue('managerSelectedReason', newReason!);
      form.setValue('contractorSelectedReason', newReason!.nameForContractor);
    }
  };

  const handleContractorReasonsChanged = () => {
    const {
      managerSelectedReason,
      contractorSelectedReason,
      customerSelectedReason,
    } = form.getValues();
    if (!managerSelectedReason && !customerSelectedReason) {
      const newReason = listReasons?.nameForManager.find(
        (elem) => elem.nameForContractor === contractorSelectedReason
      );
      form.setValue('managerSelectedReason', newReason!);
      form.setValue('customerSelectedReason', newReason!.nameForCustomer);
    }
  };

  const handleFormSubmit = async (data: IManagerRejectApplicationForm) => {
    const dataQuery: IManagerRejectApplicationParams = {
      businessApplicationId,
      businessRequestId,

      internalCauseName: data.managerSelectedReason?.name || '',
      internalComment: data.managerComment,

      customerCauseName: data.customerIsWriteReason
        ? data.customerWriteReason
        : data.customerSelectedReason || '',
      customerComment: data.customerComment,

      contractorCauseName: data.contractorIsWriteReason
        ? data.contractorWriteReason
        : data.contractorSelectedReason || '',
      contractorComment: data.contractorComment,

      requiredComment: !!data.managerSelectedReason?.requiredComment,
    };

    try {
      await setManagerRejectApplicationQuery(dataQuery).unwrap();
      handleShowModalWindow();
    } catch {
      // ignore
    }
  };

  return (
    <Dialog
      maxWidth={false}
      open={isShowModalWindow}
      close={handleShowModalWindow}>
      {isLoadingListReasons ? (
        <DialogSkeleton width="1100px" height="800px" />
      ) : (
        <>
          <DialogTitle className={styles.containerTitle}>
            <Typography variant="h2" fontWeight="600">
              Вы уверены, что хотите отказаться от заявки?
            </Typography>
          </DialogTitle>

          <DialogContent className={styles.containerContent}>
            <Form className={styles.list} form={form} viewMode>
              <div className={styles.listColumn}>
                <Typography variant="h2" fontSize="16px" lineHeight="20px">
                  Причины отказа для менеджера
                </Typography>

                <ReasonsManager
                  onKamReasonsChanged={handleKamReasonsChanged}
                  items={listReasons?.nameForManager}
                  reason={currentManagerSelectedReason}
                />
              </div>

              <div className={styles.listColumn}>
                <Typography variant="h2" fontSize="16px" lineHeight="20px">
                  Причины отказа для заказчика
                </Typography>

                <ReasonsCustomer
                  isWriteReason={form.watch('customerIsWriteReason')}
                  items={listReasons?.nameForCustomer}
                  onCustomerReasonsChanged={handleCustomerReasonsChanged}
                />
              </div>

              <div className={styles.listColumn}>
                <Typography variant="h2" fontSize="16px" lineHeight="20px">
                  Причины отказа для исполнителя
                </Typography>

                <ReasonsContractor
                  isWriteReason={form.watch('contractorIsWriteReason')}
                  items={listReasons?.nameForContractor}
                  onContractorReasonsChanged={handleContractorReasonsChanged}
                />
              </div>
            </Form>
          </DialogContent>

          <DialogActions className={styles.containerActions}>
            <div>
              <Typography color="error">
                {errorListReasons
                  ? getErrorMessage(
                      errorListReasons,
                      'Произошла ошибка при загрузке списка'
                    )
                  : errorRejectApplication &&
                    getErrorMessage(
                      errorRejectApplication,
                      'Произошла ошибка при отмене заявки'
                    )}
              </Typography>
            </div>

            <Button
              loading={isLoadingRejectApplication}
              onClick={form.handleSubmit(handleFormSubmit)}>
              Отказаться от заявки
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
