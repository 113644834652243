import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DialogContent, DialogTitle } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Dialog, Form, Typography } from 'src/components/ui';
import { getSortedUsers, joinFullName } from 'src/utils';
import { getErrorMessage } from 'src/store/utils';
import { ROLES } from 'src/constants';
import {
  TAssignedManager,
  useGetAssignedManagersListMutation,
  useUpdateAssignmentOrganizationManagerMutation,
} from 'src/api/admin/company';
import { CompanyTypes, ICompany } from 'src/models';
import {
  getAssignedManagerType,
  getFilteredManagersByCompanyType,
} from 'src/utils/company';

export interface IChangeManagerDialogProps {
  open: boolean;
  onClose: () => void;
  companyData: ICompany;
  companyType: CompanyTypes;
  onRefetch?: any;
}

interface IForm {
  assign: TAssignedManager | null;
}

export const ChangeManagerDialog: FC<IChangeManagerDialogProps> = ({
  open,
  onClose,
  companyData,
  companyType,
  onRefetch,
}) => {
  const form = useForm<IForm>({
    defaultValues: {
      assign: null,
    },
  });

  const [getAssignedManagers, { isLoading }] =
    useGetAssignedManagersListMutation();

  const [
    assignOrganizationManager,
    { error, isLoading: updateAssignManagerLoading },
  ] = useUpdateAssignmentOrganizationManagerMutation();

  const [assignedManagerList, setAssignedManagerList] = useState<
    TAssignedManager[]
  >([]);

  const manager =
    companyData.assignedManagers[getAssignedManagerType(companyType)];

  const onSubmit: SubmitHandler<{ assign: TAssignedManager | null }> = (
    data
  ) => {
    if (!data.assign?.userId) {
      return;
    }

    assignOrganizationManager({
      organizationId: companyData.id,
      userId: +data.assign.userId,
      role: companyType === 'CONTRACTOR' ? ROLES.KAM_PLUS : ROLES.KAM,
    })
      .unwrap()
      .then(onRefetch)
      .then(() => {
        onClose();
      });
  };

  useEffect(() => {
    getAssignedManagers({})
      .unwrap()
      .then((res) => {
        setAssignedManagerList(res);
      })
      .then(() => {
        const defaultValueManager: TAssignedManager = {
          userId: manager?.id!,
          middleName: manager?.middleName!,
          firstName: manager?.firstName!,
          lastName: manager?.lastName!,
          role: companyType === 'CONTRACTOR' ? [ROLES.KAM_PLUS] : [ROLES.KAM],
        };

        form.setValue('assign', defaultValueManager);
      });
  }, [companyType]);

  const filteredManagersList = useMemo(
    () => getFilteredManagersByCompanyType(companyType, assignedManagerList),
    [assignedManagerList]
  );

  const options = useMemo(
    () => getSortedUsers(filteredManagersList),
    [filteredManagersList]
  );

  const fetchApiFilter = useCallback(
    (value: string) => {
      const filteredItems =
        (value
          ? filteredManagersList?.filter(
              (element) =>
                element.lastName?.toLowerCase().includes(value.toLowerCase()) ||
                element.firstName
                  ?.toLowerCase()
                  .includes(value.toLowerCase()) ||
                element.middleName?.toLowerCase().includes(value.toLowerCase())
            )
          : filteredManagersList) || [];

      return Promise.resolve(getSortedUsers(filteredItems));
    },
    [filteredManagersList]
  );

  return (
    <Dialog open={open} close={onClose}>
      <DialogTitle>Смена менеджера</DialogTitle>
      <DialogContent>
        <Typography>
          Выберите менеджера среди действующего списка КАМов и КАМов+.
        </Typography>
        <Form<IForm>
          form={form}
          onCancel={() => onClose()}
          onSubmit={onSubmit}
          loading={isLoading || updateAssignManagerLoading}>
          <Form.Item name="assign">
            <Autocomplete<TAssignedManager>
              popupIcon
              getOptionLabel={(option) =>
                joinFullName(
                  option.lastName,
                  option.firstName,
                  option.middleName
                ) || ''
              }
              isOptionEqualToValue={(option, value) =>
                option.userId === value.userId
              }
              options={options}
              fetchApi={fetchApiFilter}
              fetchOnEmptyInput
              placeholder="Выбрать"
            />
          </Form.Item>
          {error && (
            <Typography color="error">
              {getErrorMessage(error, 'Ошибка при попытке сменить менеджера')}
            </Typography>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};
