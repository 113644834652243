import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Link } from 'src/components/ui';
import { PERMISSIONS, ROLES } from 'src/constants';
import { NotificationType } from 'src/models/notifications';
import { AuthService, RolePermissionService } from 'src/services';

type TParams = Partial<
  {
    bidId: string;
    bidNumber: string;
    bidTitle: string;
    kamEmail: string;
    kamFullName: string;
    kamPhone: string;
    companyName: string;
    companyId: string;
    kpCost: string;
    kpNumber: string;
    declineUserFullName: string;
    declineUser: string;
    companyRole: string;
    userFullName: string;
    startAt: string;
    commercialOfferDuedate: string;
    paymentDate: string;
    causeName: string;
    minutes: string;
    date: string;
    time: string;
    downloadLink: string;
    downloadLinkTitle: string;
    shippingStatus: string;
    cost: string;
    declineReason: string;
    shippingDate: string;
    customerCompanyId: string;
    customerCompanyName: string;
    contractorCompanyId: string;
    contractorCompanyName: string;
    roleUrl: string;
    contactFullName: string;
    attributes: string;

    initiatorFullName: string;
    businessApplicationNumber: string;
    businessRequestId: string;
    businessRequestName: string;

    cargoDescription: string;
    cargoParameters: string;
    deliveryAddress: string;
    organizationName: string;
    shipmentAddress: string;
    totalCost: string;
    bidNumberWithTitle: string;
    contractUrl: string;
    contractId: string;
    organizationId: string;
    organizationType: string;
  } & { [key in `file_${number}_${string}`]: string } & {
    [key in `file_deleted_${number}`]: string;
  }
>;

type THref = {
  [key in keyof TParams]: string;
};

type TLink = {
  link: string;
  label: string;
};

export enum ECompanyRoleType {
  'CONTRACTOR, CUSTOMER' = 'CONTRACTOR, CUSTOMER',
  'CONTRACTOR' = 'CONTRACTOR',
  'CUSTOMER' = 'CUSTOMER',
}

const getHrefs = (
  params: TParams,
  notificationType?: NotificationType
): Record<string, TLink> => {
  const hrefs: THref = {
    bidId: 'requests',
    bidNumber: 'requests',
    bidTitle: 'requests',
    kamFullName: '',
    kamPhone: '',
    kamEmail: '',
    companyName: 'companies',
    kpCost: '',
    kpNumber: '',
    declineUserFullName: '',
    declineUser: '',
    userFullName: '',
    startAt: '',
    contractorCompanyName: 'companies',
    customerCompanyName: 'companies',
    downloadLinkTitle: '',
    shippingStatus: '',

    initiatorFullName: '',
    businessApplicationNumber: 'requests',
    businessRequestName: 'requests',
    bidNumberWithTitle: '',
    contractUrl: '',
    organizationName: '',
  };

  const getRequestLink = (key: keyof TParams) => {
    if (notificationType) {
      if (
        notificationType === 'QUOTATION_AGREEMENT' ||
        notificationType === 'LOGISTICS_CALCULATION_KP'
      ) {
        return `/${hrefs[key]}/${params.businessRequestId}/commercial-offers`;
      }
      if (
        notificationType === 'SPECIFICATION_AGREEMENT' ||
        notificationType === 'LOGISTICS_CALCULATION_SPECIFICATION'
      ) {
        return `/${hrefs[key]}/${params.businessRequestId}/specifications`;
      }
      if (notificationType === 'REQUEST_AGREEMENT') {
        return `/${hrefs[key]}/${params.businessRequestId}/customer-request`;
      }
    }
    return `/${hrefs[key]}/${params.businessRequestId}`;
  };

  const getContractLink = () => {
    let companyLinkPart =
      params.companyRole === ECompanyRoleType.CONTRACTOR
        ? 'contractors/'
        : 'customers/';

    if ([ROLES.CUSTOMER, ROLES.CONTRACTOR].includes(AuthService.currentRole!)) {
      companyLinkPart = '';
    }

    return `/companies/${companyLinkPart}${params.organizationId}/contract/${params.contractId}`;
  };

  const isManager = !RolePermissionService.can(PERMISSIONS.VIEW_OWN_COMPANY);

  const dataLinks: Record<string, TLink> = {};

  const keys = Object.keys(hrefs) as Array<keyof TParams>;
  let companyLink: string;

  const getBidLink = (key: keyof THref) => {
    if (
      notificationType === 'CONTRACTOR_COMMERCIAL_OFFER_SENT_TO_MANAGER' ||
      notificationType === 'COMMERCIAL_OFFER_IMPROVEMENT_REQUEST'
    ) {
      return `/${hrefs[key]}/${params.bidId}/commercial-offers`;
    }

    return `/${hrefs[key]}/${params.bidId}`;
  };

  keys.forEach((key) => {
    if (params) {
      switch (key) {
        case 'kamFullName':
          dataLinks[key] = {
            link: '',
            label: params.kamFullName || '',
          };
          break;
        case 'userFullName':
          dataLinks[key] = {
            link: '',
            label: params.userFullName || '',
          };
          break;
        case 'kpCost':
          dataLinks[key] = {
            link: '',
            label: params.kpCost || '',
          };
          break;
        case 'kpNumber':
          dataLinks[key] = {
            link: '',
            label: params.kpNumber || '',
          };
          break;
        case 'kamPhone':
          dataLinks[key] = {
            link: `tel:${params.kamPhone}`,
            label: `${params.kamPhone}` || '',
          };
          break;
        case 'kamEmail':
          dataLinks[key] = {
            link: `mailto:${params.kamEmail}`,
            label: params.kamEmail || '',
          };
          break;
        case 'bidNumber':
          dataLinks[key] = {
            link: getBidLink(key),
            label: `${params.bidNumber}` || '',
          };
          break;
        case 'bidTitle':
          dataLinks[key] = {
            link: getBidLink(key),
            label: `${params.bidTitle}` || '',
          };
          break;
        case 'companyName':
          companyLink = `/${params.roleUrl}`;
          if (!params.roleUrl) {
            companyLink = isManager
              ? `/${hrefs[key]}/${
                  params.companyRole === ECompanyRoleType.CONTRACTOR
                    ? 'contractors'
                    : 'customers'
                }/${params.companyId}`
              : `/${hrefs[key]}/${params.companyId}`;
          }
          dataLinks[key] = {
            link: companyLink,
            label: `${params.companyName}` || '',
          };
          break;
        case 'contractorCompanyName':
          dataLinks[key] = {
            link: isManager
              ? `/${hrefs[key]}/contractors/${
                  params.contractorCompanyId ?? params.companyId
                }`
              : `/${hrefs[key]}/${params.companyId}`,
            label: `${params.contractorCompanyName}` || '',
          };
          break;
        case 'customerCompanyName':
          dataLinks[key] = {
            link: isManager
              ? `/${hrefs[key]}/customers/${
                  params.customerCompanyId ?? params.companyId
                }`
              : `/${hrefs[key]}/${params.companyId}`,
            label: `${params.customerCompanyName}` || '',
          };
          break;
        case 'shippingStatus':
          dataLinks[key] = {
            link: '',
            label: `“${params.shippingStatus}”` || '',
          };
          break;

        case 'initiatorFullName':
          dataLinks[key] = {
            link: '',
            label: params.initiatorFullName || '',
          };
          break;
        case 'businessApplicationNumber':
          dataLinks[key] = {
            link: getRequestLink(key),
            label: `${params.businessApplicationNumber}` || '',
          };
          break;
        case 'businessRequestName':
          dataLinks[key] = {
            link: getRequestLink(key),
            label: params.businessRequestName || '-',
          };
          break;

        case 'cargoDescription':
          dataLinks[key] = {
            link: ``,
            label: params.cargoDescription || '',
          };
          break;

        case 'cargoParameters':
          dataLinks[key] = {
            link: ``,
            label: params.cargoParameters || '',
          };
          break;

        case 'deliveryAddress':
          dataLinks[key] = {
            link: ``,
            label: params.deliveryAddress || '',
          };
          break;

        case 'shipmentAddress':
          dataLinks[key] = {
            link: ``,
            label: params.shipmentAddress || '',
          };
          break;

        case 'organizationName':
          dataLinks[key] = {
            link:
              notificationType === 'CONTRACTOR_SIGNING_ESK_SOB_REQUESTED'
                ? `/companies/contractors/${params.companyId}`
                : '',
            label: params.organizationName || '',
          };
          break;

        case 'totalCost':
          dataLinks[key] = {
            link: ``,
            label: params.totalCost?.toString() || '',
          };
          break;

        case 'bidNumberWithTitle':
          dataLinks[key] = {
            link: `/requests/${params.businessRequestId}/commercial-offers`,
            label: params.bidNumberWithTitle?.toString() || '',
          };
          break;

        case 'contractUrl':
          dataLinks[key] = {
            link: getContractLink(),
            label: 'на платформу по ссылке',
          };
          break;

        default:
          break;
      }
    }
  });

  return dataLinks;
};

const isParams = (phrase: string, keysParams: Array<keyof TParams>) =>
  keysParams.find((key) => phrase.includes(key));

const getLink = (
  phrase: string,
  dataLinks: Record<string, TLink>,
  params: TParams
) => {
  const clearPhrase = phrase as keyof TParams;
  const contactLinks = ['kamPhone', 'kamEmail'];
  const withoutLink = [
    'kamFullName',
    'declineReason',
    'comment',
    'kpCost',
    'kpNumber',
    'declineUserFullName',
    'declineUser',
    'userFullName',
    'startAt',
    'commercialOfferDuedate',
    'paymentDate',
    'causeName',
    'minutes',
    'date',
    'time',
    'shippingStatus',
    'cost',
    'shippingDate',
    'contactFullName',
    'initiatorFullName',
    'cargoDescription',
    'cargoParameters',
    'shipmentAddress',
    'deliveryAddress',
    'totalCost',
    'deadlineDate',
    'statusMessageText',
    'organizationType',
  ];

  if (contactLinks.includes(clearPhrase) && dataLinks[clearPhrase]) {
    return (
      <Link href={dataLinks[clearPhrase].link}>
        {dataLinks[clearPhrase].label}
      </Link>
    );
  }

  if (
    (withoutLink.includes(clearPhrase) || !dataLinks[clearPhrase].link) &&
    params[clearPhrase]
  ) {
    return <span>{dataLinks[clearPhrase]?.label ?? params[clearPhrase]}</span>;
  }

  return (
    <Link component={RouterLink} to={dataLinks[clearPhrase]?.link}>
      {dataLinks[clearPhrase]?.label}
    </Link>
  );
};

const canSpace = (phrase: string) => {
  const chars = ['.', ':', ','];
  return !phrase?.split('').find((char) => chars.includes(char));
};

const clearPhrase = (phrase: string) => phrase.replace('<br/>', '');

type TFillingNotificationTemplate = {
  template: string;
  params: TParams;
  onDownload?: (id: number) => Promise<null>;
  attributes?: {
    name: string;
  }[];
  notificationType?: NotificationType;
};

export const fillingNotificationTemplate = ({
  params,
  template,
  attributes,
  onDownload,
  notificationType,
}: TFillingNotificationTemplate): ReactNode => {
  const hrefs = getHrefs(params, notificationType);
  const keysParams = Object.keys(params) as Array<keyof TParams>;

  const phrases = template
    .replace(/\n/g, '<br/> ')
    .replace(/[“${}”]/g, ' ')
    .split(' ')
    .map((phrase, index, array) => {
      if (isParams(phrase, keysParams)) {
        return (
          <span key={index}>
            {getLink(phrase, hrefs, params)}
            {canSpace(array[index + 1]) && <>&nbsp;</>}
            {phrase.includes('<br/>') && <br />}
          </span>
        );
      }
      if (phrase === 'attributes' && attributes) {
        return (
          <ul
            key={index}
            style={{
              marginBottom: 0,
            }}>
            {attributes.map((elem, attrIndex) => {
              const isLastElem = attrIndex === attributes.length - 1;
              return (
                <li
                  key={elem.name}
                  style={{
                    marginBottom: !isLastElem ? '16px' : 0,
                  }}>
                  {elem.name}
                </li>
              );
            })}
          </ul>
        );
      }
      return (
        <span key={index}>
          {clearPhrase(phrase) !== '' && <>{clearPhrase(phrase)} &nbsp;</>}
          {phrase.includes('<br/>') && <br />}
        </span>
      );
    });
  const fileRegex = /file_(\d+)_([^\s]+)/;
  const fileDeletedRegex = /file_deleted_(\d+)/;

  const deletedFiles =
    Object.entries(params)
      .map(([key, value]) => {
        if (fileDeletedRegex.test(key)) {
          return value;
        }
        return null;
      })
      .filter(Boolean) ?? [];

  const files =
    Object.entries(params)
      .map(([key, value]) => {
        if (fileRegex.test(key)) {
          const match = key.match(fileRegex);
          const id = match ? Number(match[1]) : undefined;
          const extension = match ? match[2] : '';
          return {
            id,
            title: `${value}.${extension}`,
          };
        }
        return null;
      })
      .filter(Boolean) ?? [];
  return (
    <div
      style={{
        height: '100%',
        wordWrap: 'break-word',
        maxWidth: '100%',
      }}>
      {phrases}
      {params.downloadLinkTitle && params.downloadLink && (
        <div>
          <Link href={params.downloadLink}>{params.downloadLinkTitle}</Link>
        </div>
      )}
      {deletedFiles.map((fileName, index) => (
        <div key={index}>{fileName}</div>
      ))}
      {files.length > 0 && (
        <>
          <div>Файлы, приложенные к запросу:</div>
          {files.map((file) => (
            <div key={file?.id}>
              <Button variant="text" onClick={() => onDownload?.(file?.id!)}>
                {file?.title}
              </Button>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
