import { FC } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { AddTableItemIcon, DocumentTemplatesIcon } from 'src/assets/icons';
import {
  Autocomplete,
  Button,
  Form,
  FormLabel,
  InfoRow,
  Link,
  Stack,
} from 'src/components/ui';
import { OpenChat } from 'src/components/widgets';
import {
  ConversationEntityType,
  IBusinessRequestCustomerInfo,
  IManagerCustomerSpecification,
} from 'src/models';
import { IChangeLogs } from 'src/models/changelogs';
import { IActiveContract } from 'src/models/contract';
import { required } from 'src/utils/form-rules';
import {
  Deadlines,
  FilesInput,
  Finance,
  PaymentConditions,
} from '../components';

interface ICustomerSpecificationProps {
  viewMode: boolean;
  specification: IManagerCustomerSpecification | undefined;
  checkRequired: boolean;
  orderProcessingStarted: boolean;
  businessRequestId: number;
  isQuickDeal: boolean;
  shippingIncluded: boolean;

  costHistory?: IChangeLogs;
  supplyDurationHistory?: IChangeLogs;
  paymentPeriodProlongationHistory?: IChangeLogs;
  paymentConditionsHistory?: IChangeLogs;
  customerContracts: IActiveContract[];
  customerData?: IBusinessRequestCustomerInfo;
}

const mode = 'customer';

export const CustomerSpecification: FC<ICustomerSpecificationProps> = ({
  viewMode,
  specification,
  checkRequired,
  businessRequestId,
  isQuickDeal,
  orderProcessingStarted,
  shippingIncluded,
  costHistory,
  supplyDurationHistory,
  paymentPeriodProlongationHistory,
  paymentConditionsHistory,
  customerContracts,
  customerData,
}) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const getContractLabel = (contract: IActiveContract) => {
    if (contract.contractNumber && contract.templateName) {
      return `${contract.contractNumber} - ${contract.templateName}`;
    }

    if (contract.contractNumber) {
      return contract.contractNumber;
    }

    if (contract.templateName) {
      return contract.templateName;
    }

    return 'Договор без номера и шаблона';
  };

  return (
    <>
      {specification?.customerData && (
        <InfoRow
          label="Заказчик"
          value={
            <Link
              state={{ path: pathname }}
              component={RouterLink}
              to={`/companies/customers/${specification.customerData.organizationId}`}>
              {specification.customerData.organizationName}
            </Link>
          }
        />
      )}

      <Stack
        direction="column"
        gap="8px"
        marginBottom={viewMode ? '0px' : '24px'}>
        <Form.Item
          noMargin
          name="contractData"
          label="Договор"
          viewMode={viewMode}
          hideNotRequiredLabel
          rules={{
            required: checkRequired ? required : undefined,
          }}
          renderView={(item) => (
            <InfoRow
              label="Договор"
              value={
                item ? (
                  <Link
                    component={RouterLink}
                    to={`/companies/customers/${
                      specification!.customerData.organizationId
                    }/contract/${item.contractId}`}>
                    {getContractLabel(item)}
                  </Link>
                ) : undefined
              }
            />
          )}>
          <Autocomplete<IActiveContract>
            disabled={!customerContracts || customerContracts.length === 0}
            getOptionLabel={(option) => getContractLabel(option)}
            options={customerContracts}
            fetchApi={(value) =>
              Promise.resolve(
                value
                  ? customerContracts.filter(
                      (contract) =>
                        contract.contractNumber?.includes(value) ||
                        contract.templateName?.includes(value)
                    )
                  : customerContracts
              )
            }
            fetchOnEmptyInput
            placeholder="Выберите договор из списка"
          />
        </Form.Item>

        {!viewMode && customerContracts.length === 0 && (
          <FormLabel error>У компании нет ни одного договора</FormLabel>
        )}

        {!viewMode && (
          <Stack direction="row" alignItems="center" gap="20px">
            {customerContracts.length !== 0 && (
              <Button
                variant="text"
                applyNewIconSizing
                startIcon={<DocumentTemplatesIcon />}
                onClick={() =>
                  navigate(
                    `/companies/customers/${
                      customerData!.organizationId
                    }/contract`
                  )
                }>
                Перейти к договорам
              </Button>
            )}
            <Button
              variant="text"
              applyNewIconSizing
              startIcon={<AddTableItemIcon />}
              onClick={() =>
                navigate(
                  `/companies/customers/${
                    customerData!.organizationId
                  }/about?mode=addContract`
                )
              }>
              Создать новый
            </Button>
          </Stack>
        )}
      </Stack>

      <Finance
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
        shippingIncluded={shippingIncluded}
        costHistory={costHistory}
      />

      <Deadlines
        supplyDurationHistory={supplyDurationHistory}
        paymentPeriodProlongationHistory={paymentPeriodProlongationHistory}
        viewMode={viewMode}
        checkRequired={checkRequired}
      />

      <PaymentConditions
        viewMode={viewMode}
        checkRequired={checkRequired}
        paymentConditionsHistory={paymentConditionsHistory}
      />

      {!orderProcessingStarted && (
        <OpenChat
          entityType={ConversationEntityType.BUSINESS_REQUEST}
          entityId={businessRequestId}
          mode="manager"
          sx={{ marginBottom: '24px' }}
        />
      )}

      <FilesInput
        specification={specification}
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
        isQuickDeal={isQuickDeal}
      />
    </>
  );
};
