import { Typography } from 'src/components/ui';
import cn from 'classnames';
import { HintTextVariant, IHintTextProps } from './hint-text.types';

import styles from './hint-text.module.scss';

export const HintText = ({
  children,
  variant = HintTextVariant.DEFAULT,
}: IHintTextProps) => (
  <Typography
    className={cn({
      [styles.warning]: variant === HintTextVariant.WARNING,
    })}
    fontSize="10px"
    lineHeight="1.2"
    paddingTop="8px">
    {children}
  </Typography>
);
