import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  Autocomplete,
} from 'src/components/ui';
import {
  TAssignedManager,
  useGetAssignedManagersListMutation,
} from 'src/api/admin/company';
import { DialogSkeleton } from 'src/components/ui/skeleton';
import { getErrorMessage } from 'src/store/utils';
import { useForm } from 'react-hook-form';
import { useSetAssignmentManagerMutation } from 'src/api/business-request/business-request';
import { IManagerBusinessRequest } from 'src/models';
import { ROLES } from 'src/constants';
import { joinFullName, getSortedUsers } from 'src/utils';
import { TModeChangeManager } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/assigned-managers/assigned-managers';
import { useGetUserListByRoleQuery } from 'src/api/user';

interface IChangeManagerProps {
  open: boolean;
  businessRequest: IManagerBusinessRequest;
  close: () => void;
  mode: TModeChangeManager;
}

interface IInputForm {
  manager: TAssignedManager;
}

export const ChangeManager: FC<IChangeManagerProps> = ({
  open,
  businessRequest,
  close,
  mode,
}) => {
  const [getAssignedManagers, { data: listManagers, isLoading }] =
    useGetAssignedManagersListMutation();
  const [setAssignmentManager, { isLoading: isLoadingSetManager }] =
    useSetAssignmentManagerMutation();

  const { data: techExpertList } = useGetUserListByRoleQuery(
    ROLES.TECH_EXPERT,
    {
      refetchOnMountOrArgChange: true,
      skip: mode !== 'tech_expert',
    }
  );

  const form = useForm<IInputForm>({
    defaultValues: {
      manager: {
        userId: 0,
        firstName: '',
        lastName: '',
        middleName: '',
        role: [ROLES.KAM],
      },
    },
  });

  const handleSubmitForm = async (data: IInputForm) => {
    try {
      await setAssignmentManager({
        businessRequestId: businessRequest.businessRequestId,
        userId: data.manager.userId,
        type: mode,
      })
        .unwrap()
        .then(() => {
          close();
        })
        .catch((e) => {
          form.setError('root.serverError', {
            type: 'custom',
            message: getErrorMessage(
              e,
              'Произошла ошибка при сохранении данных'
            ),
          });
        });
    } catch {
      // ignore
    }
  };

  const fetchApiFilter = useCallback(
    (value: string) => {
      const items =
        mode === 'kam' || mode === 'kam_plus' ? listManagers : techExpertList;

      const filteredItemsBySearch =
        (value
          ? items?.filter(
              (element) =>
                element.lastName?.toLowerCase().includes(value.toLowerCase()) ||
                element.firstName
                  ?.toLowerCase()
                  .includes(value.toLowerCase()) ||
                element.middleName?.toLowerCase().includes(value.toLowerCase())
            )
          : items) || [];

      let filteredItems: TAssignedManager[] = [];

      if (mode === 'tech_expert') {
        filteredItems = filteredItemsBySearch;
      } else {
        filteredItems = filteredItemsBySearch.filter(
          (element) =>
            !element.role.includes(mode === 'kam' ? ROLES.KAM_PLUS : ROLES.KAM)
        );
      }

      return Promise.resolve(getSortedUsers(filteredItems));
    },
    [listManagers, mode, techExpertList]
  );

  const options = useMemo(() => {
    const items =
      mode === 'kam' || mode === 'kam_plus' ? listManagers : techExpertList;

    const filteredItems = items?.filter((element) => {
      if (mode === 'kam') {
        return element.role.includes(ROLES.KAM);
      }

      if (mode === 'kam_plus') {
        return element.role.includes(ROLES.KAM_PLUS);
      }

      if (mode === 'tech_expert') {
        return element?.roles?.includes(ROLES.TECH_EXPERT);
      }

      return false;
    });

    return getSortedUsers(filteredItems);
  }, [listManagers, mode, techExpertList]);

  useEffect(() => {
    if (mode === 'kam' || mode === 'kam_plus') {
      getAssignedManagers({});
    }
  }, [getAssignedManagers, mode]);

  const title = useMemo(() => {
    if (mode === 'kam') {
      return 'Смена менеджера по заявке';
    }

    if (mode === 'kam_plus') {
      return 'Смена менеджера по заказу';
    }

    return 'Смена техэксперта по заявке';
  }, [mode]);

  return (
    <Dialog
      open={open}
      close={close}
      PaperProps={{
        sx: {
          width: '500px',
        },
      }}>
      {isLoading ? (
        <DialogSkeleton width="500px" height="318px" />
      ) : (
        <>
          <DialogTitle>{title}</DialogTitle>

          <DialogContent>
            <Form
              form={form}
              onSubmit={handleSubmitForm}
              loading={isLoadingSetManager}>
              <Form.Item
                viewMode={false}
                required
                label={
                  mode === 'tech_expert'
                    ? 'Выберите техэксперта'
                    : 'Выберите менеджера'
                }
                name="manager">
                <Autocomplete<TAssignedManager>
                  popupIcon
                  getOptionLabel={(option) =>
                    joinFullName(
                      option.lastName,
                      option.firstName,
                      option.middleName
                    ) || ''
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.userId === value.userId
                  }
                  options={options}
                  fetchApi={fetchApiFilter}
                  fetchOnEmptyInput
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
