export const FIELD_LENGTH = {
  login: 100,
  password: [8, 50],
  fullName: 400,
  firstName: 200,
  secondName: 200,
  patronymic: 100,
  position: 200,
  organizationName: 200,
  organizationFullName: 500,
  organizationDescription: 1000,
  inn: [10, 12],
  kpp: 9,
  okpo: [8, 10],
  okved: [4, 12],
  ogrn: [13, 15],
  basis: 400,
  bic: 9,
  correspondentAccount: 20,
  bankName: 300,
  swift: [8, 11],
  checkingAccount: 20,
  iban: 34,
  esk: 19,
  comment: 800,
  notificationName: 40,
  commentForContractor: 2500,
  businessRequestDescription: 2500,
  contractTemplateName: 200,
  addressComment: 800,
  cargoDescription: {
    tentCount: 3,
  },
  reason: 250,
  cars: 3,
  nameRequest: 200,
  excludeContractor: 1000,
  rejectApplicationComment: 1000,
  logisticItemDescription: 2000,
  logisticItemParameters: 2000,
  logisticItemComment: 2000,
  cost: 12,
  commercialOffer: {
    price: 15,
    costsLogistics: 12,
    paymentConditions: 1000,
    commentOffer: 1000,
    supplyDuration: 3,
    paymentPeriodProlongation: 3,
  },
  refusalReason: 255,
  newsForm: {
    title: 120,
    summary: 500,
    content: 10_000,
  },
  categoryForm: {
    name: 50,
  },
  specifications: {
    paymentConditions: 1000,
    comment: 1000,
  },
  refusedCommercialOfferComment: 1000,
  technicalOpportunitiesSecondLevel: 255,

  shipping: {
    shipmentDetails: 1000,
    shippingCost: 12,
    cargoCost: 15,
  },

  notifications: {
    templateDescription: 200,
  },

  user: {
    amoId: 20,
    sipuniKamNumber: 9,
  },

  auction: {
    initialMaxCost: 12,
    costDropLimit: 12,
    autoProlongationDuration: 2,

    auctionBidCost: 12,
    stepType: {
      fixedCost: 12,
      initialMaxCostPercent: 3,
      maxNumber: 100,
    },
    rejectReason: 1000,
    excludeReason: 1000,
  },
  rebidding: {
    initialMaxCost: 12,
    comment: 1000,
    refusedComment: 500,
  },

  sob: {
    comment: 2000,
  },

  invokeEmployee: {
    comment: 500,
  },
  refuseContractor: 1000,
} as const;
