import { DIRECTION } from 'src/components/ui/filters/types';
import { TDocument } from 'src/models/document';
import {
  ContractorSpecificationStatus,
  CustomerSpecificationStatus,
  ManagerContractorSpecificationStatus,
} from 'src/models/specifications';

export interface IContract {
  contractTemplateId: null | number;
  isGeneratedFromTemplate: boolean;
  contractId: number;
  templateName?: string;
  status: ContractStatuses;
  contractType: 'CONTRACTOR' | 'CUSTOMER';
  signDate: string;
  validityEndDate: string;
  signatoryFio: string;
  basis: string;
  documents: {
    [key in ContractDocumentTypes]: (TDocument & {
      key: string;
      documentType: ContractDocumentTypes;
      entityType: 'CONTRACTOR' | 'CUSTOMER';
      entityId: number;
    })[];
  };
  organizationId: number;
  contractNumber?: string;
  specificationDetails: {
    applicationId: number;
    applicationName: string;
    applicationNumber: number;
    businessRequestId: number;
    id: number;
    statusForContractorOrCustomer:
      | CustomerSpecificationStatus
      | ContractorSpecificationStatus;
    statusForKam: ManagerContractorSpecificationStatus;
    businessRequestName: string;
  }[];
}

export enum ContractStatuses {
  DRAFT_UPLOAD = 'DRAFT_UPLOAD',
  DRAFT_APPROVAL = 'DRAFT_APPROVAL',
  FINAL_CONTRACT_CREATION = 'FINAL_CONTRACT_CREATION',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
}

export enum ContractDocumentTypes {
  DRAFT_CONTRACT = 'DRAFT_CONTRACT',
  SIGNED_CONTRACT_SCAN = 'SIGNED_CONTRACT_SCAN',
  FINAL_CONTRACT = 'FINAL_CONTRACT',
  DISAGREEMENT_PROTOCOL = 'DISAGREEMENT_PROTOCOL',
}

export enum ContractHistoryActionType {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  STAGED = 'STAGED',
  MODIFIED = 'MODIFIED',
}

export interface IContractHistory {
  id: number;
  documentName: string;
  createdAt: string;
  createdByFio: string;
  actionType: ContractHistoryActionType;
  actionAt: string;
  actionByFio: string;
  size: number;
  documentType: ContractDocumentTypes;
  actionByUserId: number;
  createdByUserId: number;
}

export interface IActiveContract {
  contractId: number;
  organizationId: number;
  contractNumber: string;
  nds: string;
  templateName?: string;
}

export interface IGetContractHistoryParams {
  id: number;
  direction: DIRECTION;
}

export interface IContractAddress {
  address: string;
  city: string;
  region: string;
}
