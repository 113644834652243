import { TPhoneInputValue } from 'src/components/ui/phone-input';
import {
  ContractDocumentTypes,
  DocumentTypes,
  IAccountingBlock,
  IFinancialBlock,
  TDocument,
  TTechnicalOpportunity,
} from 'src/models';
import {
  ContractorStatusForContractor,
  ContractorStatusForKam,
} from 'src/models/contractor';
import { ROLES } from '../constants';
import { HelpExpertStatuses } from '../components/ui';

export interface IOrganizationDetails {
  amocrmId?: number;
  organizationName?: string;
  organizationFullName?: string;
  kpp?: string;
  okpo?: string;
  okved?: string;
  ogrn?: string;
  withNds?: boolean;
  legalAddress?: string;
  actualAddress?: string;
  lastModifiedAt?: string;
  websiteLink?: string | null;
  region?: string;
  city?: string;
  organizationDescription?: string | null;
  headFio?: string;
  headPosition?: string;
  email?: string;
  phone?: string;
}

// Todo: после перехода на множественные реквизиты на страницах менеджера и админа
// проверить, используется ли данный интерфейс где-то еще
export interface IOrganizationBankDetails {
  bic?: string;
  bankName?: string;
  checkingAccount?: string;
  correspondentAccount?: string;
  swift?: string;
  iban?: string;
}

export interface IOrganizationBankRequisites extends IOrganizationBankDetails {
  id: number;
  isMain: boolean;
  bic: string;
}

export type TAddOrganizationBankRequisites = {
  organizationId: number;
  organizationRole: CompanyTypes;
  bic: string;
  bankName?: string;
  checkingAccount?: string;
  correspondentAccount?: string;
  swift?: string;
  iban?: string;
  isMain: boolean;
};

export interface ICommonCompany {
  id: number;
  inn: string;
  organizationDetails?: IOrganizationDetails;
  organizationBankDetails?: IOrganizationBankDetails;
  contractorSigningDate?: string;
}

export interface IAddCompanyRoleResponse {
  organizationId: number;
  organizationName: string;
  roles: CompanyTypes[];
}

export interface ICompany extends ICommonCompany {
  documents: {
    [key in DocumentTypes]?: TDocument[];
  };
  organizationStatus: {
    id?: number;
    statusName?: string;
    description?: string;
    code: CompanyStatuses;
  };
  roles: CompanyTypes[];
  assignedManagers: {
    [key in MangerTypes]?: {
      id: number;
      login: string;
      lastName: string;
      firstName: string;
      middleName: string;
      position: string;
      contactInfoDto: {
        phones: TPhoneInputValue[];
      };
    };
  };
  technicalAuditDate: string | null;
  technicalOpportunitiesCategories: TTechnicalOpportunity[];
  comment: string | null;
  customerType?: string;
  segmentId?: number;
  segmentName?: string;
  esk?: number;
  isKeyCustomer?: boolean;
  contractorSigning?: {
    currentContractorStatus: ContractorStatusForContractor;
    currentKamPlusStatus: ContractorStatusForKam;
    id: number;
    sobApproveStatus: string;
    sobComment: string;
  };
}

export enum CompanyTypes {
  'CUSTOMER' = 'CUSTOMER',
  'CONTRACTOR' = 'CONTRACTOR',
}

export enum CompanyStatuses {
  'ACTIVE' = 'ACTIVE',
  'LOCKED' = 'LOCKED',
  'SHOWS_NO_ACTIVITY' = 'SHOWS_NO_ACTIVITY',
  'LIMITED' = 'LIMITED',
}

export enum MangerTypes {
  'KAM' = 'kam',
  'KAM_PLUS' = 'kamPlus',
  'TECH_EXPERT' = 'techExpert',
}

export interface IOrganizationsNamesLite {
  organizationId: number;
  organizationName: string;
  organizationFullName: string;
}

export interface ISegment {
  id: number;
  name: string;
}

export interface IRefuseSegment {
  id: number;
  segmentName: string;
}

export interface IKonturFocusData {
  registrationDate: string;
  leader?: string;
  leaderPosition?: string;
  leaderStartDate?: string;
  managementCompany?: string;
  innManagementCompany: string;
  ogrn: string;
  isIp: boolean;
  citizenship?: string;
  appointmentDate: string;
  specialTaxRegime?: string;
  okveds: {
    code: string;
    name: string;
    primaryActivity: boolean;
  }[];
  employees?: {
    employeeCount: number;
    year: number;
  }[];
  financeInfo: {
    year: string;
    revenue: string;
    revenueDifference: string;
    netIncome: string;
    netIncomeDifference: string;
    price: string;
    priceDifference: string;
    accountReceivable: string;
    accountReceivableDifference: string;
    accountPayable: string;
    accountPayableDifference: string;
  };
  financialIndicators: {
    indicatorType: string;
    amount: number; // null
    amountUnit: string;
    growth: string;
    year: string;
  }[];
  warnings: {
    name: string;
    items: {
      status: string;
      title: string;
      description: string;
    }[];
  }[];
  financialStatements: {
    type: string;
    name: string;
    designation: string;
    description: string;
    coefficient: string;
    icon: string;
  }[];
  judicalInformation: {
    caseName: string;
    date: string;
    defendant: string;
    amount: string;
    category: string;
    description: string;
    status: string;
    caseLink: string;
  }[];
  debtInformation: {
    name: string;
    date: string;
    status: string;
    subject: string;
    documentDetails: string;
  }[];
  accountingInformation: {
    title: string;
    type: string;
    name: string;
    value: string;
    unit: string;
  }[];
  accountingTablesInformation: {
    tableTitle: string;
    tableInfos: {
      firstLevel: string;
      nestedTableInfos: {
        firstLevel: string;
        substrings: string[];
      }[];
    }[];
    financeResultRows: string[];
  }[];

  // TODO: Выпилить лишнее после того, как закончим все задачи по контуру
  accountingBlocks: IAccountingBlock[];
  financialBlocks: IFinancialBlock[];
}

export interface IFindDocumentsResponseDto {
  id: number;
  name: string;
  documentType: ContractDocumentTypes;
  createdByUser: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  createdAt: string;
  sizeBytes: number;
  status: string;
  key: string;
  entityType: string;
  entityId: number;
}

export interface IContractAssignments {
  id: number;
  contractorSigningId: number;
  assignedRole: ROLES;
  assignmentStatus: HelpExpertStatuses;
  createdAt: string;
  updatedAt: string;
  summonComment: string;
  assignedRoleComment: string;
  findDocumentsResponseDto: IFindDocumentsResponseDto[];
}

export interface IContractAssignmentsResponseDto {
  assignments: IContractAssignments[];
}
