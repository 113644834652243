import { Autocomplete, Form, HintText, OutlinedInput } from 'src/components/ui';
import { maxLength, required } from 'src/utils/form-rules';
import { FC, useCallback, useState } from 'react';
import { FIELD_LENGTH } from 'src/constants';
import { HintTextVariant } from 'src/components/ui/hint-text/hint-text.types';
import { IManagerRejectApplicationForm } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/reject-application/components/manager/types';
import styles from './reasons-manager.module.scss';

interface IReasonsManagerProps {
  items:
    | {
        id: number;
        name: string;
        nameForCustomer: string;
        nameForContractor: string;
        status: string;
      }[]
    | undefined;
  onKamReasonsChanged: () => void;
  reason: IManagerRejectApplicationForm['managerSelectedReason'] | null;
}

export const ReasonsManager: FC<IReasonsManagerProps> = ({
  items,
  onKamReasonsChanged,
  reason,
}) => {
  const fetchApiFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? items?.filter((element) =>
              element.name.toLowerCase().includes(value.toLowerCase())
            )
          : items) || []
      ),
    [items]
  );

  const [isApprovalNeeded, setIsApprovalNeeded] = useState<boolean>(false);

  return (
    <>
      <Form.Item
        noMargin
        name="managerSelectedReason"
        label="Выберите внутреннюю причину отказа"
        rules={{
          required,
        }}
        help={
          isApprovalNeeded && (
            <HintText variant={HintTextVariant.WARNING}>
              Для отклонения заявки по указанной причине необходимо
              подтверждение Руководителя ТО
            </HintText>
          )
        }>
        <Autocomplete
          popupIcon
          options={items}
          fetchApi={fetchApiFilter}
          getOptionLabel={(opt) => opt.name}
          onChange={(item) => {
            onKamReasonsChanged();
            setIsApprovalNeeded(false);
            // @ts-ignore
            if (item && 'approvalNeeded' in item) {
              setIsApprovalNeeded(!!item.approvalNeeded);
            }
          }}
          fetchOnEmptyInput
          placeholder="Выбрать"
        />
      </Form.Item>

      <div className={styles.indent} />

      <Form.Item
        noMargin
        name="managerComment"
        label="Напишите внутренний комментарий"
        rules={{
          required: !!reason?.requiredComment,
          maxLength: maxLength(FIELD_LENGTH.rejectApplicationComment),
        }}>
        <OutlinedInput
          placeholder="Введите комментарий"
          multiline
          rows={6}
          maxLength={FIELD_LENGTH.rejectApplicationComment}
        />
      </Form.Item>
    </>
  );
};
