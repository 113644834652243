import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';
import { ADMIN_BASENAME, ROUTES } from 'src/configs';
import { AuthService, RolePermissionService } from 'src/services';
import { TRouteConfig } from 'src/configs/routes';
import { useAppSelector } from 'src/store';
import {
  selectCurrentRole,
  selectIsAuth,
  selectIsGuestAuth,
} from 'src/store/auth';
import { setDefaultOptions } from 'date-fns';
import { PERMISSIONS } from 'src/constants';
import { Snowflakes } from 'src/components/widgets';
import styles from './app.module.css';

setDefaultOptions({ locale: ru });

const getAvailableRoutes = (
  config: TRouteConfig,
  isAuth?: boolean,
  basename?: string
) =>
  config.reduce((acc: RouteObject[], item) => {
    const { permissions, ...routeItem } = item;

    if (!routeItem.path || !isAuth || RolePermissionService.can(permissions)) {
      const resultRouteItem =
        isAuth &&
        routeItem.path &&
        basename &&
        routeItem.path.startsWith(basename)
          ? {
              ...routeItem,
              path: routeItem.path.replace(basename, '/'),
            }
          : routeItem;

      if (resultRouteItem.children) {
        const availableChildItems = getAvailableRoutes(
          resultRouteItem.children,
          isAuth,
          basename
        );

        if (availableChildItems.length) {
          acc.push({ ...resultRouteItem, children: availableChildItems });
        }
      } else {
        acc.push(resultRouteItem);
      }
    }

    return acc;
  }, []);

export const App = () => {
  const isAuth = useAppSelector(selectIsAuth);
  const isGuestAuth = useAppSelector(selectIsGuestAuth);
  const role = useAppSelector(selectCurrentRole);
  let basename;

  if (isGuestAuth) {
    basename = `/u${AuthService.userId}`;
  } else if (RolePermissionService.can(PERMISSIONS.VIEW_ADMIN_PANEL)) {
    basename = ADMIN_BASENAME;
  }

  const router = createBrowserRouter(
    getAvailableRoutes(ROUTES, isAuth, basename),
    {
      basename,
    }
  );

  return (
    <div className={styles.app} key={role}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </div>
  );
};
