import { createApi } from '@reduxjs/toolkit/dist/query/react';
// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { contactsApi } from 'src/api/contacts';
import { baseQueryWithReauth } from 'src/store/utils';
import { DIRECTION, TConfigFilter } from 'src/components/ui/filters/types';
import {
  TGetOrganizationTagsItem,
  TGetOrganizationTagsParams,
  TRequestCompany,
  IAdminCompanyList,
  IAdminCompanyListParams,
} from 'src/models/admin/company';
import { CompanyStatuses, IStatusHistory, KamStatusCode } from 'src/models';
import { ROLES } from 'src/constants';

export type TSortKey = 'REGISTRATION_DATE' | 'ORGANIZATION_NAME';
export type TCompanyListParams = {
  sort: {
    field: TSortKey;
    order: DIRECTION;
  };
  paging: {
    page: number;
    limit: number;
  };
  searchQueries: Array<string>;
  filter: TCompanyFilterParams;
};

export type TCompanyFilters = {
  [key in TConfigFilter['name']]: Array<string> | Array<number>;
};

export type TCreateCompanyResponse = {
  id: number;
};

export type TOrganization = { id: number; organizationName: string };
export type TApplication = { id: number; name: string };
export type TOrganizationAssignment = {
  managerId: number;
  organizations: TOrganization[];
  applications?: TApplication[];
};

export type TAssignedManager = {
  userId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  role: ROLES[];
  roles?: ROLES[];
};

export type TAssignmentOrganizationManager = {
  organizationId: number;
  userId: number;
  role: ROLES;
};

export type TCompanyFilterParams = {
  organizationIds: Array<string>;
  organizationNames: Array<string>;
  roles: Array<ROLES>;
  inns: Array<string>;
  organizationStatuses: Array<string>;
  contractStatuses: Array<string>;
};

export type TReassignmentByOrganizationParams = {
  oldKamId: number;
  newKamId: number;
};

export const adminCompanyApi = createApi({
  reducerPath: 'adminCompanyApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAssignmentList: builder.mutation<
      TOrganizationAssignment[],
      { managerIds: number[] }
    >({
      query: (body) => ({
        url: 'mph-security-provider/api/admin/v1/user/assignment',
        method: 'POST',
        body,
      }),
    }),

    reassignmentKam: builder.mutation<
      void,
      TReassignmentByOrganizationParams[]
    >({
      query: (body) => ({
        url: '/organizations/api/v1/organization/reassignment/kam',
        method: 'POST',
        body,
      }),
    }),

    reassignmentKamPlus: builder.mutation<
      void,
      TReassignmentByOrganizationParams[]
    >({
      query: (body) => ({
        url: '/organizations/api/v1/organization/reassignment/kam-plus',
        method: 'POST',
        body,
      }),
    }),

    getFilters: builder.mutation<TCompanyFilters, void>({
      query: () => ({
        url: 'organizations/api/v1/organization/filters',
        method: 'GET',
      }),
      transformResponse({ inn, ...filters }: TCompanyFilters) {
        return { inns: inn, ...filters };
      },
    }),
    getAssignedManagersList: builder.mutation<
      TAssignedManager[],
      { roles?: string }
    >({
      query: (params) => ({
        url: '/mph-security-provider/api/admin/v1/user/managers',
        method: 'GET',
        params,
      }),
    }),
    updateAssignmentOrganizationManager: builder.mutation<
      void,
      TAssignmentOrganizationManager
    >({
      query: (body) => ({
        url: `/organizations/api/v1/organization/${
          body.organizationId
        }/assigment/${body.role.toLowerCase()}/${body.userId}`,
        method: 'PUT',
      }),
    }),
    updateStatusCompany: builder.mutation<
      void,
      {
        status: CompanyStatuses;
        organizationIds: number[];
      }
    >({
      query: ({ status, organizationIds }) => ({
        url: 'organizations/api/admin/v1/organization/status',
        method: 'PATCH',
        body: {
          organizationIds,
          status,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(contactsApi.util.invalidateTags(['Contacts']));
        } catch {
          throw new Error();
        }
      },
    }),
    createCompany: builder.mutation<TCreateCompanyResponse, TRequestCompany>({
      query: (body) => ({
        url: '/organizations/api/v1/organization/simple/system',
        method: 'POST',
        body,
      }),
    }),
    getOrganizationTags: builder.query<
      TGetOrganizationTagsItem[],
      TGetOrganizationTagsParams
    >({
      query: (body) => ({
        url: 'organizations/api/admin/v1/organization/technical-opportunities',
        body,
        method: 'POST',
      }),
    }),

    getCompanyList: builder.mutation<
      IAdminCompanyList,
      IAdminCompanyListParams
    >({
      query: (body) => ({
        url: 'organizations/api/admin/v2/organization',
        method: 'POST',
        body,
      }),
    }),

    deleteCompany: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `organizations/api/v2/organization/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUpdateStatusCompanyMutation,
  useGetAssignmentListMutation,
  useGetFiltersMutation,
  useReassignmentKamMutation,
  useReassignmentKamPlusMutation,
  useCreateCompanyMutation,
  useGetAssignedManagersListMutation,
  useUpdateAssignmentOrganizationManagerMutation,
  useLazyGetOrganizationTagsQuery,
  useGetCompanyListMutation,
  useDeleteCompanyMutation,
} = adminCompanyApi;
